<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2015-12-09',
                text: '營業收入公告 ( 104年11月份 )',
                url: '../upfile/ctxt_343.pdf'
            },
            {
                date: '2015-11-09',
                text: '營業收入公告 ( 104年10月份 )',
                url: '../upfile/ctxt_342.pdf'
            },
            {
                date: '2015-10-07',
                text: '營業收入公告 ( 104年09月份 )',
                url: '../upfile/ctxt_341.pdf'
            },
            {
                date: '2015-09-08',
                text: '營業收入公告 ( 104年08月份 )',
                url: '../upfile/ctxt_339.pdf'
            },
            {
                date: '2015-08-10',
                text: '營業收入公告 ( 104年07月份 )',
                url: '../upfile/ctxt_338.pdf'
            },
            {
                date: '2015-07-08',
                text: '營業收入公告 ( 104年06月份 )',
                url: '../upfile/ctxt_337.pdf'
            },
            {
                date: '2015-06-08',
                text: '營業收入公告 ( 104年05月份 )',
                url: '../upfile/ctxt_331.pdf'
            },
            {
                date: '2015-05-08',
                text: '營業收入公告 ( 104年04月份 )',
                url: '../upfile/ctxt_328.pdf'
            },
            {
                date: '2015-04-09',
                text: '營業收入公告 ( 104年03月份 )',
                url: '../upfile/ctxt_318.pdf'
            },
            {
                date: '2015-03-09',
                text: '營業收入公告 ( 104年02月份 )',
                url: '../upfile/ctxt_316.pdf'
            },
            {
                date: '2015-02-09',
                text: '營業收入公告 ( 104年01月份 )',
                url: '../upfile/ctxt_314.pdf'
            },
            {
                date: '2015-01-08',
                text: '營業收入公告 ( 103年12月份 )',
                url: '../upfile/ctxt_308.pdf'
            },
        ]

        return { Lists }
    }
}
</script>