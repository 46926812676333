<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2012-12-10',
                text: '營業收入公告 ( 101年11月份 )',
                url: '../upfile/ctxt_202.pdf'
            },
            {
                date: '2012-11-10',
                text: '營業收入公告 ( 101年10月份 )',
                url: '../upfile/ctxt_201.pdf'
            },
            {
                date: '2012-10-09',
                text: '營業收入公告 ( 101年09月份 )',
                url: '../upfile/ctxt_200.pdf'
            },
            {
                date: '2012-09-10',
                text: '營業收入公告 ( 101年08月份 )',
                url: '../upfile/ctxt_199.pdf'
            },
            {
                date: '2012-08-07',
                text: '營業收入公告 ( 101年07月份 )',
                url: '../upfile/ctxt_157.pdf'
            },
            {
                date: '2012-07-09',
                text: '營業收入公告 ( 101年06月份 )',
                url: '../upfile/ctxt_153.pdf'
            },
            {
                date: '2012-06-09',
                text: '營業收入公告 ( 101年05月份 )',
                url: '../upfile/ctxt_152.pdf'
            },
            {
                date: '2012-05-09',
                text: '營業收入公告 ( 101年04月份 )',
                url: '../upfile/ctxt_151.pdf'
            },
            {
                date: '2012-04-09',
                text: '營業收入公告 ( 101年03月份 )',
                url: '../upfile/ctxt_150.pdf'
            },
            {
                date: '2012-03-10',
                text: '營業收入公告 ( 101年02月份 )',
                url: '../upfile/ctxt_149.pdf'
            },
            {
                date: '2012-02-10',
                text: '營業收入公告 ( 101年01月份 )',
                url: '../upfile/ctxt_125.pdf'
            },
            {
                date: '2012-01-08',
                text: '財務報告',
                url: 'https://mops.twse.com.tw/mops/web/t57sb01_q1#'
            },
        ]

        return { Lists }
    }
}
</script>