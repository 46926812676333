<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },

    setup() {
        const Lists = [
            {
                date: '2023-12-11',
                text: '營業收入公告 ( 112年11月份 )',
                url: '../upfile/f2023_11.pdf'
            },
            {
                date: '2023-11-16',
                text: '本公司受邀參加櫃買中心舉辦之「櫃買市場業績發表會」(中文)',
                url: '../upfile/TTFB2023DT_tw.pdf'
            },
            {
                date: '2023-11-16',
                text: '本公司受邀參加櫃買中心舉辦之「櫃買市場業績發表會」(英文)',
                url: '../upfile/TTFB2023DT_en.pdf'
            },
            {
                date: '2023-11-09',
                text: '營業收入公告 ( 112年10月份 )',
                url: '../upfile/f2023_10.pdf'
            },
            {
                date: '2023-10-11',
                text: '營業收入公告 ( 112年09月份 )',
                url: '../upfile/f2023_09.pdf'
            },
            {
                date: '2023-09-11',
                text: '營業收入公告 ( 112年08月份 )',
                url: '../upfile/f2023_08.pdf'
            },
            {
                date: '2023-08-08',
                text: '營業收入公告 ( 112年07月份 )',
                url: '../upfile/f2023_07.pdf'
            },
            {
                date: '2023-07-17',
                text: '瓦城泰統111年度財報-合併財報-英文版',
                url: '../upfile/fs111_1e.pdf'
            },
            {
                date: '2023-07-17',
                text: '瓦城泰統111年度財報-個體財報-英文版',
                url: '../upfile/fs111_2e.pdf'
            },
            {
                date: '2023-07-10',
                text: '營業收入公告 ( 112年06月份 )',
                url: '../upfile/f2023_06.pdf'
            },
            {
                date: '2023-06-08',
                text: '營業收入公告 ( 112年05月份 )',
                url: '../upfile/f2023_05.pdf'
            },
            {
                date: '2023-05-08',
                text: '	本公司受邀參加元大證券法人說明會(中文)',
                url: '../upfile/TTFB2023Q1tw.pdf'
            },
            {
                date: '2023-05-08',
                text: '	本公司受邀參加元大證券法人說明會(英文)',
                url: '../upfile/TTFB2023Q1en.pdf'
            },
            {
                date: '2023-05-08',
                text: '營業收入公告 ( 112年04月份 )',
                url: '../upfile/f2023_04.pdf'
            },
            {
                date: '2023-04-11',
                text: '營業收入公告 ( 112年03月份 )',
                url: '../upfile/f2023_03.pdf'
            },
            {
                date: '2023-03-31',
                text: '瓦城泰統111年度財報-合併財報',
                url: '../upfile/fs111_1.pdf'
            },
            {
                date: '2023-03-31',
                text: '瓦城泰統111年度財報-個體財報',
                url: '../upfile/fs111_2.pdf'
            },
            {
                date: '2023-03-09',
                text: '營業收入公告 ( 112年02月份 )',
                url: '../upfile/f2023_02.pdf'
            },
            {
                date: '2023-02-09',
                text: '營業收入公告 ( 112年01月份 )',
                url: '../upfile/f2023_01.pdf'
            },
            {
                date: '2023-01-09',
                text: '營業收入公告 ( 111年12月份 )',
                url: '../upfile/f2022_12.pdf'
            },
        ]

        return { Lists }
    }
}
</script>