<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },

    setup() {
        const Lists = [
            {
                date: '2022-12-07',
                text: '營業收入公告 ( 111年11月份 )',
                url: '../upfile/f2022_11.pdf'
            },
            {
                date: '2022-11-30',
                text: '本公司受邀參加國泰證券法人說明會(中文)',
                url: '../upfile/3Q22_handout_tw_202211.pdf'
            },
            {
                date: '2022-11-30',
                text: '本公司受邀參加國泰證券法人說明會(英文)',
                url: '../upfile/3Q22_handout_en_202211.pdf'
            },
            {
                date: '2022-11-10',
                text: '營業收入公告 ( 111年10月份 )',
                url: '../upfile/f2022_10.pdf'
            },
            {
                date: '2022-10-20',
                text: '	1111020-本公司受邀參加第一金證券法人說明會(中文)',
                url: '../upfile/2022_1111_1H22_handout_tw.pdf'
            },
            {
                date: '2022-10-20',
                text: '	1111020-本公司受邀參加第一金證券法人說明會(英文)',
                url: '../upfile/2022_1111_1H22_handout_en.pdf'
            },
            {
                date: '2022-10-14',
                text: '1111014-本公司受邀參加永豐金大證券法人說明會(英文)',
                url: '../upfile/2022_0915_1H22_handout_en.pdf'
            },
            {
                date: '2022-10-14',
                text: '1111014-本公司受邀參加永豐金大證券法人說明會(中文)',
                url: '../upfile/2022_0915_1H22_handout_tw.pdf'
            },
            {
                date: '2022-10-14',
                text: '1111014-本公司受邀參加犇亞證券法人說明會證券(英文)',
                url: '../upfile/2022_0915_1H22_handout_en.pdf'
            },
            {
                date: '2022-10-14',
                text: '1111014-本公司受邀參加犇亞證券法人說明會證券(中文)',
                url: '../upfile/2022_0915_1H22_handout_tw.pdf'
            },
            {
                date: '2022-10-13',
                text: '1111013-本公司受邀參加統一證券法人說明會(英文)',
                url: '../upfile/2022_0915_1H22_handout_en.pdf'
            },
            {
                date: '2022-10-13',
                text: '1111013-本公司受邀參加統一證券法人說明會(中文)',
                url: '../upfile/2022_0915_1H22_handout_tw.pdf'
            },
            {
                date: '2022-10-07',
                text: '營業收入公告 ( 111年9月份 )',
                url: '../upfile/f2022_09.pdf'
            },
            {
                date: '2022-09-15',
                text: '1110915-本公司受邀參加統一證券2022 Q3全球展望秋季投資論壇(英文)',
                url: '../upfile/2022_0915_1H22_handout_en.pdf'
            },
            {
                date: '2022-09-15',
                text: '1110915-本公司受邀參加統一證券2022 Q3全球展望秋季投資論壇(中文)',
                url: '../upfile/2022_0915_1H22_handout_tw.pdf'
            },
            {
                date: '2022-09-14',
                text: '本公司受邀參加KGI 2022年第三季投資論壇(英文)',
                url: '../upfile/2022_0914_1H22_handout_en.pdf'
            },
            {
                date: '2022-09-14',
                text: '本公司受邀參加KGI 2022年第三季投資論壇(中文)',
                url: '../upfile/2022_0914_1H22_handout_tw.pdf'
            },
            {
                date: '2022-09-08',
                text: '本公司受邀參加元大證券法人說明會(英文)',
                url: '../upfile/2022_1H22_handout_en.pdf'
            },
            {
                date: '2022-09-08',
                text: '本公司受邀參加元大證券法人說明會(中文)',
                url: '../upfile/2022_1H22_handout_tw.pdf'
            },
            {
                date: '2022-09-07',
                text: '營業收入公告 ( 111年8月份 )',
                url: '../upfile/f2022_08.pdf'
            },
            {
                date: '2022-08-10',
                text: '營業收入公告 ( 111年7月份 )',
                url: '../upfile/f2022_07.pdf'
            },
            {
                date: '2022-07-10',
                text: '營業收入公告 ( 111年6月份 )',
                url: '../upfile/f2022_06.pdf'
            },
            {
                date: '2022-06-10',
                text: '營業收入公告 ( 111年5月份 )',
                url: '../upfile/f2022_05.pdf'
            },
            {
                date: '2022-05-10',
                text: '營業收入公告 ( 111年4月份 )',
                url: '../upfile/f2022_04.pdf'
            },
            {
                date: '2022-04-11',
                text: '營業收入公告 ( 111年3月份 )',
                url: '../upfile/f2022_03.pdf'
            },
            {
                date: '2022-04-19',
                text: '本公司受邀參加國泰綜合證券法人座談會(中文）',
                url: '../upfile/fTTFB2021results_Handout_c.pdf'
            },
            {
                date: '2022-04-19',
                text: '本公司受邀參加國泰綜合證券法人座談會(English）',
                url: '../upfile/fTTFB2021results_Handout_e.pdf'
            },
            {
                date: '2022-03-10',
                text: '營業收入公告 ( 111年2月份 )',
                url: '../upfile/f2022_02.pdf'
            },
            {
                date: '2022-02-14',
                text: '營業收入公告 ( 111年1月份 )',
                url: '../upfile/f2022_01.pdf'
            },
            {
                date: '2022-02-10',
                text: '營業收入公告 ( 110年12月份 )',
                url: '../upfile/f2021_11012.pdf'
            },
        ]

        return { Lists }
    }
}
</script>