<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2021-08-30',
                text: '110股東常會議事錄',
                url: '../upfile/s2021_1230.pdf'
            },
            {
                date: '2021-05-31',
                text: '109年年報',
                url: '../upfile/ctxt_577.pdf'
            },
            {
                date: '2021-05-17',
                text: '110年股東會議事手冊',
                url: '../upfile/ctxt_576.pdf'
            },
            {
                date: '2021-05-17',
                text: '110年股東會開會通知',
                url: '../upfile/ctxt_575.pdf'
            },
            {
                date: '2021-05-17',
                text: '瓦城泰統股份有限公司章程',
                url: '../upfile/ss2022_1.pdf'
            },
        ]

        return { Lists }
    }
}
</script>