<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },

    setup() {
        const Lists = [
            {
                date: '2024-12-10',
                text: '營業收入公告 ( 113年11月份 )',
                url: '../upfile/f2024_11.pdf'
            },
            {
                date: '2024-11-28',
                text: '本公司受邀參加富邦證券法人說明會 (中文)',
                url: '../upfile/TTFBfor2024Q3zh.pdf'
            },
            {
                date: '2024-11-28',
                text: '本公司受邀參加富邦證券法人說明會 (英文)',
                url: '../upfile/TTFBfor2024Q3en.pdf'
            },
            {
                date: '2024-11-14',
                text: '	本公司受邀參加凱基證券法人說明會  (中文)',
                url: '../upfile/TTFB2024Q3zh.pdf'
            },
            {
                date: '2024-11-14',
                text: '	本公司受邀參加凱基證券法人說明會  (英文)',
                url: '../upfile/TTFB2024Q3en.pdf'
            },
            {
                date: '2024-11-11',
                text: '營業收入公告 ( 113年10月份 )',
                url: '../upfile/f2024_10.pdf'
            },
            {
                date: '2024-10-08',
                text: '營業收入公告 ( 113年09月份 )',
                url: '../upfile/f2024_09.pdf'
            },
            {
                date: '2024-09-10',
                text: '營業收入公告 ( 113年08月份 )',
                url: '../upfile/f2024_08.pdf'
            },
            {
                date: '2024-08-12',
                text: '營業收入公告 ( 113年07月份 )',
                url: '../upfile/f2024_07.pdf'
            },
            {
                date: '2024-07-09',
                text: '營業收入公告 ( 113年06月份 )',
                url: '../upfile/f2024_06.pdf'
            },
            {
                date: '2024-06-11',
                text: '營業收入公告 ( 113年05月份 )',
                url: '../upfile/f2024_05.pdf'
            },
            {
                date: '2024-05-09',
                text: '營業收入公告 ( 113年04月份 )',
                url: '../upfile/f2024_04.pdf'
            },
            {
                date: '2024-04-10',
                text: '營業收入公告 ( 113年03月份 )',
                url: '../upfile/f2024_03.pdf'
            },
            {
                date: '2024-03-11',
                text: '營業收入公告 ( 113年02月份 )',
                url: '../upfile/f2024_02.pdf'
            },
            {
                date: '2024-02-15',
                text: '營業收入公告 ( 113年01月份 )',
                url: '../upfile/f2024_01.pdf'
            },
            {
                date: '2024-01-10',
                text: '營業收入公告 ( 112年12月份 )',
                url: '../upfile/f2023_12.pdf'
            },

        ]

        return { Lists }
    }
}
</script>