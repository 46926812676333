<template>
<div class="w-900 mx-auto mb-6">
    <img src="@/assets/about_t2.jpg">
    <div class="flex">
        <div class="mr-4">
            <h1 class="my-2">企業使命</h1>
            <p>我們的使命，就是以「真心為你」創造顧客心中最好的餐廳。</p>
            <p>為了達到最高的顧客滿意度，我們自我要求對每一位顧客的服務、每一道料理的呈現，所有的細部環節都是以「真心為你」為出發點，將服務存於心、美味握於手，以行動實踐創造出顧客心中最好的餐廳！</p>
            <h1 class="mb-2 mt-6">企業文化</h1>
            <p class="text-sm font-bold py-1">【堅】 – 堅持的理念 &emsp; &emsp;【忍】 – 熱誠的態度</p>
            <p class="text-sm font-bold pb-3">【謙】 – 謙虛的處事 &emsp; &emsp;【恭】 – 恭敬的責任</p>
            <p>《堅忍謙恭》源自於徐承義董事長自小修習跆拳道而悟出的道理，更是我們的企業文化。在每一間分店，都可以看到由徐承義董事長親筆題字的《堅忍謙恭》書法掛於員工休息區，以潛移默化的方式讓大家保持最佳狀態，身體力行於日常生活中，培養出發自內心的敬重與認同感，自我要求並且感染週遭，拿出那份對料理／服務的堅持，維持一致的高水準，以熱誠的態度服務每一位蒞臨的顧客，胼手胝足共同為了傳承東方料理的美味而努力著！</p>
            <h1 class="mb-2 mt-6">企業方針</h1>
            <p class="text-sm font-bold py-1">- 我們要求達成 最高的顧客滿意</p>
            <p class="text-sm font-bold py-1">- 我們務必創造 最好的獲利</p>
            <p class="text-sm font-bold py-1">- 我們專心培育 最優秀的團隊</p>
            <p class="text-sm font-bold py-1">- 我們致力突破 反應快速及持續創新</p>
            <p class="mt-3">30年來堅持最佳品質，我們每一天都用心經營，</p>
            <p>儲存紮實的能量、創立獨特的制度、落實方針，</p>
            <p>期許成為世界第一大東方美食連鎖餐飲集團！</p>
        </div>
        <div class="flex-none">
            <img src="@/assets/about_t2a.jpg">
        </div>
    </div>
</div>
</template>

<script>
export default {}
</script>