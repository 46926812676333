<template>
<div class="w-900 mx-auto mb-10">
    <img src="@/assets/news_t.jpg">
    <h1 class="mb-6 text-xl text-red-600">看板人物專訪瓦城泰統集團董事長徐承義，30年堅持的美味關鍵</h1>
    <p class="mb-3">30年一步一腳印走來，我們有著許多的故事，更充滿感恩！這些日子裡，最感謝的就是所有客人們，若您曾是瓦城泰統集團任一品牌的顧客【瓦城/ 大心/ 時時香/ 1010湘/ 非常泰/ YABI KITCHEN/ 月月泰BBQ/十食湘Bistro】，我們深深一鞠躬~ 有您的支持，真好！</p>
    <p class="mb-3">我們會繼續努力，持續提供最好的服務和美味，讓您與親朋好友的任何歡聚食刻都更安心更盡興！</p>
    <p class="mb-3">邀請您一同觀賞 - TVBS 看板人物 專訪《瓦城泰統集團 徐承義董事長與他的品牌故事》。餐飲業長久經營是不容易的，尤其這一年多來世界因疫情而驟變，餐飲業者的挑戰更需要"隨勢隨時做應變"才有可能在逆風中繼續前行。</p>
    <p class="mb-8">透過這次「看板人物」節目專訪徐承義董事長，您將能瞭解瓦城長跑30年的品牌故事，一份堅持給顧客最好的品牌堅持；此次的專訪也將讓您一探徐董事長身為創業家的獨到堅毅、自律和信念。</p>
    <iframe width="900" height="506.25" src="https://www.youtube.com/embed/R_EBioluf8I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
</template>

<script>
export default {}
</script>