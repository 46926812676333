<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2016-11-15',
                text: '工商時報_瓦城前3季賺逾1個股本 Q4營收、獲利可望再創新高，全年EPS可望衝破13元',
                url: '../upfile/ctxt_375.pdf'
            },
            {
                date: '2016-11-11',
                text: '經濟日報 A19_瓦城三年內揮軍美國 「飛輪效應」加速 不排除尋找策略合作夥伴',
                url: '../upfile/ctxt_374.pdf'
            },
            {
                date: '2016-11-08',
                text: '經濟日報 C5 瓦城營收甜 連十月攀高',
                url: '../upfile/ctxt_373.pdf'
            },
            {
                date: '2016-10-11',
                text: '瓦城達百店里程碑！ Q3營收創單季新高',
                url: 'https://news.cnyes.com/news/id/3571267'
            },
            {
                date: '2016-09-08',
                text: '經濟日報 C6 上市櫃公司-瓦城Q3業績將創新高 8月營收年增8%',
                url: '../upfile/ctxt_371.pdf'
            },
            {
                date: '2016-08-11',
                text: '工商時報 B4上市櫃_瓦城雙響炮 Q2、H1獲利登頂',
                url: '../upfile/ctxt_370.pdf'
            },
            {
                date: '2016-08-09',
                text: '瓦城泰統7月營收破3.5億　創歷史次高',
                url: 'https://finance.ettoday.net/news/751717?redirect=1'
            },
            {
                date: '2016-08-03',
                text: '經濟日報 A16【瓦城徵才 起薪2.9萬元】',
                url: '../upfile/ctxt_368.pdf'
            },
            {
                date: '2016-07-13',
                text: '工商時報 A16 產業商業-瓦城泰統 中壢打造全球研發中心',
                url: '../upfile/ctxt_367.pdf'
            },
            {
                date: '2016-07-07',
                text: '工商時報 B4 上市櫃2-瓦城 6月業績年增10.28％',
                url: '../upfile/ctxt_366.pdf'
            },
            {
                date: '2016-06-07',
                text: '瓦城泰統5月營收破3.33億　估Q2可望再攀新高峰',
                url: 'https://www.nownews.com/n/2016/06/07/2127034'
            },
            {
                date: '2016-05-13',
                text: '工商時報 B4 上市櫃2-瓦城飽賺 首季EPS 3.72元新高',
                url: '../upfile/ctxt_364.pdf'
            },
            {
                date: '2016-05-10',
                text: '瓦城連假消費發威 4月營收站上3億',
                url: 'http://www.cnabc.com/news/aall/201605101116.aspx'
            },
        ]

        return { Lists }
    }
}
</script>