<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2020-10-16',
                text: '百家店高標準配備與高規服務 瓦城泰統安心口碑向上再提升',
                url: '../upfile/ctxt_567.pdf'
            },
            {
                date: '2020-10-16',
                text: '瓦城泰統集團旗下130間餐廳　全面取得國際組織SGS升級標章',
                url: '../upfile/ctxt_566.pdf'
            },
            {
                date: '2020-08-06',
                text: '	全台唯一！瓦城集團旗下130家餐廳全面取得SGS認證',
                url: '../upfile/ctxt_565.pdf'
            },
            {
                date: '2020-08-06',
                text: '日消毒上千次！餐飲集團旗下品牌SGS檢驗 高規格防疫',
                url: 'https://www.ftvnews.com.tw/news/detail/2020806F10M1'
            },
            {
                date: '2020-08-06',
                text: '指縫也不放過!SGS高規檢測逾60項目 餐廳拚認證要你吃得安心',
                url: 'https://news.ustv.com.tw/program/0/2WJbx-04VoQ'
            },
            {
                date: '2020-08-06',
                text: '不只好吃更要吃得安心！瓦城、金色三麥拼認證，追求衛生高標準',
                url: '../upfile/ctxt_562.pdf'
            },
        ]

        return { Lists }
    }
}
</script>