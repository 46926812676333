<template>
<div class="w-900 mx-auto mb-6">
    <img src="@/assets/about_t1.jpg">
    <div class="flex">
        <div class="w-490">
            <img src="@/assets/about_t1a.jpg">
            <h1 class="my-2">鎖定東方料理領域</h1>
            <p>俗謂：「民以食為天」，全世界的料理發展多元且豐富，其中「東方料理」因獨特的調理風味及食材運用發揮的極致，是全世界人們最廣為接受與喜愛美食，但卻也是最難表現的料理領域。東方料理之所以困難，原因就在於其錯綜複雜的工序，不論是步驟、刀工、調味、配料、火候、烹割，任何一個細小環節的精準掌握，在在影響色香味表現，每一次料理都不容許有任何的差池。</p>
            <p>在競爭極為激烈的餐飲市場中，瓦城泰統集團特別鎖定「東方料理」領域，希望能夠突破現今無法被複製的瓶頸，以透過系統化的發展策略，傳承東方料理不變的美味！</p>
            <h1 class="mb-2 mt-8">獨創《爐炒廚房連鎖化》系統</h1>
            <p>正所謂：「工欲善其事，必先利其器」。</p>
            <p>瓦城泰統集團，為了確實執行「傳承東方料理美味」的理想，多年來不斷鑽研發展出一套獨創的中式廚房系統，先於業界首創中式廚房的新定義為「爐炒廚房」，將中式料理獨特的鍋爐匙炒設備，視為最基礎且重要的發展環境，再剖析每道料理的製程，將之解構為數百種評估數值，從錙銖計較中發展出一整套爐炒廚房系統，讓集團旗下900多位廚師，在八個品牌近200道 菜色、選用近500~600 種的香料食材中，都能快速而精確地料理出東方美味。</p>
            <p>爐炒廚房連鎖化系統，讓顧客享受現點現做、高品質的美味料理，並讓瓦城泰統集團做到，每一家分店、每一天、呈現給每一位顧客「100%一致的色香味」！</p>
            <img src="@/assets/about_t1b.jpg">
        </div>
        <div class="flex-none">
            <img src="@/assets/about_t1c.jpg">
        </div>
    </div>
</div>
</template>

<script>
export default {}
</script>