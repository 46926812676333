<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2011-12-26',
                text: '【雜誌報導】面板良率九成 瓦城料理不良率低於千分之三 生產流程更科學！│商業周刊1257期',
                url: '../upfile/ctxt_92.pdf'
            },
            {
                date: '2011-10-31',
                text: '【影音新聞】瓦城泰統董事長專訪│聯合影音網',
                url: 'http://video.udn.com/video/Item/ItemPage.do?sno=344-233-2B4-233-2B333b3c323d4-233-2B3-2B3-2F34'
            },
            {
                date: '2011-10-10',
                text: '【雜誌報導】瓦城董事長徐承義的武道經營學│今周刊772期',
                url: '../upfile/ctxt_93.pdf'
            },
            {
                date: '2011-09-18',
                text: '	【報紙新聞】2011年9月瓦城興櫃 報紙報導彙整',
                url: '../upfile/ctxt_89.pdf'
            },
            {
                date: '2011-09-16',
                text: '為強化公司治理及健全董事會運作，選任三席獨立董事，並建立「獨立董事之職責範疇規則」',
                url: 'https://www.youtube.com/watch?v=CyTnDKBVpxM&ab_channel=kenkenmei702'
            },
            {
                date: '2011-09-16',
                text: '【網路新聞】泰式料理瓦城登錄興櫃 早盤大漲逾3成 當上興櫃高價股三哥│鉅亨網',
                url: 'https://news.cnyes.com/Content/20110916/KDZ79Q73WV87F.shtml?c=pre'
            },
            {
                date: '2011-09-16',
                text: '【網路新聞】瓦城拚拓點 業績飄香│經濟日報',
                url: 'https://udn.com/news/e404?yhaos'
            },
            {
                date: '2011-09-15',
                text: '【網路新聞】瓦城泰統集團徐承義今宣佈掛牌興櫃│中央日報',
                url: 'http://www.cdnews.com.tw/cdnews_site/docDetail.jsp?coluid=112&docid=101662863'
            },
        ]

        return { Lists }
    }
}
</script>