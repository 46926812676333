<template>
<nav class="relative flex justify-center bg-gradient-to-t from-bgdown to-bgtop">
    <div v-for="(bg, index) in bgImg" :key="index">
        <div :currentNavBg="currentNavBg">
            <div v-show="currentNavBg === index + 1">
                <img class="mx-auto" :src="bg.img">
            </div>
        </div>
    </div>

    <div class="bg-bgnav absolute -bottom-0 w-900 h-12">
        <ul class="flex justify-center items-center text-sm tracking-wider my-2 space-x-10 text-white">
            <li >
                <router-link @click="goToBg(index = 0)" :to="{ name: 'Home' }"><img src="@/assets/logo.png"></router-link>
            </li>
            <li>
                <router-link  @click="goToBg(index = 1)" :to="{ name: 'About' }">集團簡介</router-link>
            </li>
            <li>
                <router-link  @click="goToBg(index = 2)" :to="{ name: 'IR' }">投資人關係</router-link>
            </li>
            <li>
                <router-link  @click="goToBg(index = 2)" :to="{ name: 'CSR' }">企業社會責任</router-link>
            </li>
            <li>
                <router-link @click="goToBg(index = 3)" :to="{ name: 'News' }">新聞專區</router-link>
            </li>
            <li>
                <router-link @click="goToBg(index = 4)" :to="{ name: 'JoinTTFB' }">歡迎人才</router-link>
            </li>
            <li>
                <router-link @click="goToBg(index = 4)" :to="{ name: 'CharitableFoundation' }">慈善基金會</router-link>
            </li>
        </ul>
    </div>
</nav>
<div class="bg-bgnav h-2"></div>
</template>

<script>
import { ref } from 'vue'
export default {
    setup() {
        const bgImg = [
            { img: require('@/assets/banner_1.jpg') },
            { img: require('@/assets/banner_2.jpg') },
            { img: require('@/assets/banner_3.jpg') },
            { img: require('@/assets/banner_4.jpg') },
            { img: require('@/assets/banner_5.jpg') }
        ]

        const currentNavBg = ref(1)

        const goToBg = index => {
            currentNavBg.value = index + 1
        }

        return { bgImg, currentNavBg, goToBg }
    }
}
</script>

<style scoped>
.router-link-active {
  color: orange;
  font-weight: bold;
}

/* .router-link-exact-active {
  color: crimson;
} */
</style>