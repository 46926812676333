<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },

    setup() {
        const Lists = [
            {
                date: '2021-11-15',
                text: '110年 第三季 財務報告書',
                url: '../upfile/f2021_q3.pdf'
            },
            {
                date: '2021-08-16',
                text: '110年 第二季 財務報告書',
                url: '../upfile/f2021_q2.pdf'
            },
            {
                date: '2021-05-17',
                text: '110 年 第一季 財務報告書',
                url: '../upfile/f2021_q1.pdf'
            },
            {
                date: '2021-03-31',
                text: '瓦城泰統109年度財報-個體財報',
                url: '../upfile/f2021_0106a.pdf'
            },
            {
                date: '2021-03-31',
                text: '瓦城泰統109年度財報-合併財報',
                url: '../upfile/f2021_0106b.pdf'
            },
            {
                date: '2021-12-10',
                text: '營業收入公告 ( 110年11月份 )',
                url: '../upfile/f2021_11011.pdf'
            },
            {
                date: '2021-11-10',
                text: '營業收入公告 ( 110年10月份 )',
                url: '../upfile/f2021_11010.pdf'
            },
            {
                date: '2021-10-12',
                text: '營業收入公告 ( 110年09月份 )',
                url: '../upfile/f2021_11009.pdf'
            },
            {
                date: '2021-09-10',
                text: '營業收入公告 ( 110年08月份 )',
                url: '../upfile/ctxt_581.pdf'
            },
            {
                date: '2021-08-10',
                text: '營業收入公告 ( 110年07月份 )',
                url: '../upfile/ctxt_580.pdf'
            },
            {
                date: '2021-07-12',
                text: '營業收入公告 ( 110年06月份 )',
                url: '../upfile/ctxt_579.pdf'
            },
            {
                date: '2021-06-10',
                text: '營業收入公告 ( 110年05月份 )',
                url: '../upfile/ctxt_578.pdf'
            },
            {
                date: '2021-05-10',
                text: '禁止內線交易宣導',
                url: '../upfile/ctxt_574a.pdf'
            },
            {
                date: '2021-04-28',
                text: '本公司受邀參加凱基證券與IR Trust共同舉辦之「2021 MIT x 現金壓力測試主題式論壇」',
                url: '../upfile/ctxt_573.pdf'
            },
            {
                date: '2021-04-12',
                text: '營業收入公告 ( 110年03月份 )',
                url: '../upfile/ctxt_572.pdf'
            },
            {
                date: '2021-03-10',
                text: '營業收入公告 ( 110年02月份 )',
                url: '../upfile/ctxt_571.pdf'
            },
            {
                date: '2021-02-17',
                text: '營業收入公告 ( 110年01月份 )',
                url: '../upfile/ctxt_569.pdf'
            },
            {
                date: '2021-01-11',
                text: '營業收入公告 ( 109年12月份 )',
                url: '../upfile/ctxt_560.pdf'
            },
        ]

        return { Lists }
    }
}
</script>