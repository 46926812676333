<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2014-10-09',
                text: '【網路新聞】展店效益佳！瓦城9月營收再創新紀錄　年增25%│NOWnews',
                url: 'https://www.nownews.com/n/2014/10/09/1448915'
            },
            {
                date: '2014-10-09',
                text: '【網路新聞】《觀光股》瓦城Q3營收飆高，Q4百貨周年慶添動能│時報',
                url: 'https://www.chinatimes.com/realtimenews/20141009004112-260410?chdtv'
            },
            {
                date: '2014-09-11',
                text: '【新聞報導】瓦城第5品牌登陸 攻上海白領│工商時報',
                url: '../upfile/ctxt_298.pdf'
            },
            {
                date: '2014-08-14',
                text: '【網路報導】瓦城上半年賺逾1.4億元 EPS 6.34元 創歷年同期新高！│鉅亨網',
                url: 'https://news.cnyes.com/news/id/1332902'
            },
            {
                date: '2014-08-15',
                text: '【新聞報導】瓦城半年報讚 營收獲利雙創高│工商時報',
                url: '../upfile/ctxt_295.pdf'
            },
            {
                date: '2014-08-07',
                text: '【網路新聞】Q3旺季營運飄香！瓦城7月營收創單月歷史次高│鉅亨網',
                url: 'https://news.cnyes.com/news/id/1305165'
            },
            {
                date: '2014-07-10',
                text: '【網路新聞】瓦城Q2業績爆香│中時電子報',
                url: 'https://www.chinatimes.com/newspapers/20140710000169-260206?chdtv'
            },
            {
                date: '2014-06-10',
                text: '【新聞報導】瓦城增三成 攀次高│經濟日報',
                url: '../upfile/ctxt_280.pdf'
            },
            {
                date: '2014-05-21',
                text: '【新聞報導】瓦城 推平價品牌 大心│工商時報',
                url: '../upfile/ctxt_278.pdf'
            },
            {
                date: '2014-05-20',
                text: '【網路新聞】瓦城新品牌「大心」開賣 吃主餐送霜淇淋│中時電子報',
                url: 'https://www.chinatimes.com/realtimenews/20140520004287-260405?chdtv'
            },
            {
                date: '2014-04-29',
                text: '【新聞報導】瓦城首季EPS3.54元 創歷史新高│自由時報',
                url: '../upfile/ctxt_271.pdf'
            },
            {
                date: '2014-04-29',
                text: '【新聞報導】瓦城Q1獲利年增23.8%│蘋果日報',
                url: '../upfile/ctxt_270.pdf'
            },
            {
                date: '2014-04-29',
                text: '【新聞報導】瓦城 首季每股大賺3.54元│工商時報',
                url: '../upfile/ctxt_269.pdf'
            },
            {
                date: '2014-04-29',
                text: '【新聞報導】瓦城 Q1每股賺3.54元│經濟日報',
                url: '../upfile/ctxt_267.pdf'
            },
            {
                date: '2014-04-29',
                text: '【網路新聞】瓦城Q1獲利年增逾2成 EPS 3.54元 創單季新高！│鉅亨網',
                url: 'https://news.cnyes.com/news/id/960069'
            },
            {
                date: '2014-03-10',
                text: '【網路新聞】瓦城2月營收年增17%續創單月新高！年底前總店數衝破百家│鉅亨網',
                url: 'https://news.cnyes.com/news/id/903413'
            },
            {
                date: '2014-02-11',
                text: '【網路新聞】好彩頭！瓦城1月營收創單月新高 看好後市展店中國│鉅亨網',
                url: 'https://news.cnyes.com/news/id/794447'
            },
            {
                date: '2014-01-27',
                text: '【雜誌報導】瓦城堅持「一致美味」 拓展兩岸百家店│今周刊',
                url: '../upfile/ctxt_260.pdf'
            },
            {
                date: '2014-01-09',
                text: '【新聞報導】上月營收／瓦城+17% 兩岸拚百店│經濟日報',
                url: '../upfile/ctxt_258.pdf'
            },
            {
                date: '2014-01-09',
                text: '【新聞報導】瓦城營收俏 拚兩岸展百店│工商時報',
                url: '../upfile/ctxt_257.pdf'
            },
            {
                date: '2014-01-09',
                text: '【新聞報導】瓦城今年兩岸總店數衝百家│蘋果日報',
                url: '../upfile/ctxt_255.pdf'
            },
            {
                date: '2014-01-08',
                text: '【新聞報導】瓦城今年兩岸衝刺百店 將推出第4品牌│旺報',
                url: '../upfile/ctxt_259.pdf'
            },
            {
                date: '2014-01-08',
                text: '【網路新聞】瓦城年營收大增逾2成 今年兩岸總店數上看100家│鉅亨網',
                url: 'https://news.cnyes.com/news/id/783447'
            },
        ]

        return { Lists }
    }
}
</script>