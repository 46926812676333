<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2023-12-29',
                text: '落實誠信經營情形',
                url: '../upfile/csr20231229a.pdf'
            },
            {
                date: '2023-12-29',
                text: '公司治理運作情形',
                url: '../upfile/csr20231229.pdf'
            },
            {
                date: '2023-09-23',
                text: '瓦城泰統股份有限公司檢舉案件調查及處理辦法',
                url: '../upfile/csr_12_2023.pdf'
            },
            {
                date: '2023-09-23',
                text: '瓦城泰統股份有限公司反賄賂及反貪腐政策',
                url: '../upfile/csr_9_2023.pdf'
            },
            {
                date: '2023-06-09',
                text: '公司前十大股東',
                url: '../upfile/csr2023.pdf'
            },
        ]

        return { Lists }
    }
}
</script>