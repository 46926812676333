<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <tr v-for="(List, index) in Lists" :key="index" v-once class="h-8 even:bg-bgtable">
            <td class="text-center">{{ List.date }}</td>
            <td class="pl-12">{{ List.text }}</td>
            <td class="text-center">
                <router-link to="/news2021">
                <DownloadIcon class="h-5 text-red-700 mx-auto cursor-pointer transition duration-300 ease-in-out transform hover:translate-y-1" />
                </router-link>
            </td>
        </tr>
        <!-- <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" /> -->
    </tbody>
</table>
</template>

<script>
// import TableLoop from '../../components/TableLoop.vue'
import { DownloadIcon } from '@heroicons/vue/outline'
export default {
    // components: { TableLoop },
    components: { DownloadIcon },
    setup() {
        const Lists = [
            {
                date: '2021-02-08',
                text: '看板人物專訪瓦城泰統集團董事長徐承義，30年堅持的美味關鍵',
                // url: 'http://localhost:8080/news2021'
            },
        ]

        return { Lists }
    }
}
</script>