<template>
<div class="relative w-900 mx-auto mb-12">
    <img src="@/assets/ir_t3.jpg">
    <h1>投資人關係聯絡人</h1>
    <p class="my-2">
        如果您在投資方面有任何問題或建議，歡迎您與我們聯繫！<br>
        ◎ 投資服務專線：02-2248-1022<br>
        ◎ 服務信箱：<a class="underline" href="mailto:IR@ttfb.com" target="_blank">IR@ttfb.com</a>
    </p>
    <div class="absolute right-0 flex items-center">
        <p class="font-semibold text-sm">年度資料： &nbsp; </p>
        <Menu as="div" class="relative z-10 inline-block text-left">
            <div>
                <MenuButton class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                    {{ currentYear }}
                    <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div class="py-1">
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'S2024'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2024</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'S2023'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2023</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'S2022'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2022</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'S2021'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2021</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'S2020'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2020</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'S2019'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2019</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'S2018'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2018</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'S2017'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2017</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'S2016'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2016</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'S2015'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2015</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'S2014'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2014</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'S2013'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2013</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'S2012'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2012</p>
                        </MenuItem>
                    </div>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</div>
<div class="w-900 mx-auto mb-10">
    <component :is="currentTabComponent" />
</div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import S2012 from '@/views/ir/SS/S2012.vue'
import S2013 from '@/views/ir/SS/S2013.vue'
import S2014 from '@/views/ir/SS/S2014.vue'
import S2015 from '@/views/ir/SS/S2015.vue'
import S2016 from '@/views/ir/SS/S2016.vue'
import S2017 from '@/views/ir/SS/S2017.vue'
import S2018 from '@/views/ir/SS/S2018.vue'
import S2019 from '@/views/ir/SS/S2019.vue'
import S2020 from '@/views/ir/SS/S2020.vue'
import S2021 from '@/views/ir/SS/S2021.vue'
import S2022 from '@/views/ir/SS/S2022.vue'
import S2023 from '@/views/ir/SS/S2023.vue'
import S2024 from '@/views/ir/SS/S2024.vue'
export default {
    components: {
        S2012, S2013, S2014, S2015, S2016, S2017, S2018, S2019, S2020, S2021, S2022, S2023, S2024,
        // UI
        Menu, MenuButton, MenuItem, MenuItems, ChevronDownIcon,
    },

    data() {
        return {
            currentTab: 'S2024',
        }
    },
    computed: {
        currentTabComponent() {
        return `${ this.currentTab.toLowerCase() }`;
        },
        currentYear() {
            return `${ this.currentTab.substr(1) }`;
        }
    }
}
</script>