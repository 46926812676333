<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2024-06-19',
                text: '113年股東常會議事錄',
                url: '../upfile/s2024_0620g.pdf'
            },
            {
                date: '2024-06-10',
                text: '112年度年報-英文版',
                url: '../upfile/s2024_0620f.pdf'
            },
            {
                date: '2024-06-10',
                text: '112年度年報',
                url: '../upfile/s2024_0620e.pdf'
            },
            {
                date: '2024-05-17',
                text: '113年股東會議手冊-英文版',
                url: '../upfile/s2024_0620d.pdf'
            },
            {
                date: '2024-05-17',
                text: '113年股東會議手冊',
                url: '../upfile/s2024_0620c.pdf'
            },
            {
                date: '2024-05-17',
                text: '113年股東會開會通知-英文版',
                url: '../upfile/s2024_0620b.pdf'
            },
            {
                date: '2024-05-17',
                text: '113年股東會開會通知',
                url: '../upfile/s2024_0620a.pdf'
            },
        ]

        return { Lists }
    }
}
</script>