<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2012-06-18',
                text: '公告瓦城泰統股份有限公司100年度現金股利相關事宜',
                url: 'https://mops.twse.com.tw/mops/web/t108sb19_q1'
            },
            {
                date: '2012-06-15',
                text: '公告本公司101年股東會重要決議事項',
                url: 'https://mops.twse.com.tw/mops/web/t51sb10_q1'
            },
            {
                date: '2012-03-26',
                text: '	公告本公司董事會決議召開一○一年度股東常會之相關事宜',
                url: 'https://mops.twse.com.tw/mops/web/t108sb16_q1'
            },
            {
                date: '2012-01-01',
                text: '投資人關係服務專線 02-6620-6999　信箱 IR@ttfb.com',
                url: ''
            },
        ]

        return { Lists }
    }
}
</script>