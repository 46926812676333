<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2013-06-24',
                text: '股東會議事錄',
                url: '../upfile/ctxt_282.pdf'
            },
            {
                date: '2013-06-24',
                text: '瓦城泰統股份有限公司資金貸與及背書保證作業程序',
                url: '../upfile/ctxt_229.pdf'
            },
            {
                date: '2013-03-26',
                text: '瓦城泰統股份有限公司董事會議事規範',
                url: '../upfile/ctxt_213.pdf'
            },
        ]

        return { Lists }
    }
}
</script>