<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2018-12-31',
                text: '107年第三季合併財報',
                url: '../upfile/ctxt_453.pdf'
            },
            {
                date: '2018-12-31',
                text: '107年第二季合併財報',
                url: '../upfile/ctxt_452.pdf'
            },
            {
                date: '2018-12-31',
                text: '107年第一季合併財報',
                url: '../upfile/ctxt_451.pdf'
            },
            {
                date: '2018-12-31',
                text: '106年度合併財報',
                url: '../upfile/ctxt_454.pdf'
            },
            {
                date: '2018-12-31',
                text: '106年度個體財報',
                url: '../upfile/ctxt_455.pdf'
            },
            {
                date: '2018-12-10',
                text: '營業收入公告 ( 107年11月份 )',
                url: '../upfile/ctxt_448.pdf'
            },
            {
                date: '2018-12-06',
                text: '本公司受邀參加凱基證券舉辦之『凱基證券 2018年第四季大中華區投資論壇(KGI Greater China Corporate Day 2018Q4)』',
                url: '../upfile/ctxt_447.pdf'
            },
            {
                date: '2018-11-12',
                text: '	營業收入公告 ( 107年10月份 )',
                url: '../upfile/ctxt_446.pdf'
            },
            {
                date: '2018-10-09',
                text: '營業收入公告 ( 107年9月份 )',
                url: '../upfile/ctxt_445.pdf'
            },
            {
                date: '2018-09-10',
                text: '營業收入公告 ( 107年8月份 )',
                url: '../upfile/ctxt_444.pdf'
            },
            {
                date: '2018-08-14',
                text: '本公司受邀參加凱基證券舉辦之『法人說明會』',
                url: '../upfile/ctxt_443.pdf'
            },
            {
                date: '2018-08-07',
                text: '營業收入公告 ( 107年7月份 )',
                url: '../upfile/ctxt_442.pdf'
            },
            {
                date: '2018-07-09',
                text: '營業收入公告 ( 107年6月份 )',
                url: '../upfile/ctxt_437.pdf'
            },
            {
                date: '2018-06-11',
                text: '營業收入公告 ( 107年5月份 )',
                url: '../upfile/ctxt_436.pdf'
            },
            {
                date: '2018-05-09',
                text: '營業收入公告 ( 107年4月份 )',
                url: '../upfile/ctxt_435.pdf'
            },
            {
                date: '2018-04-11',
                text: '本公司受邀參加107年4月13日櫃買中心、群益證券暨寬量國際共同於香港舉辦之「2018 Taiwan CEO Day」，說明本公司之營運概況、財務及業務相關資訊。',
                url: '../upfile/ctxt_434.pdf'
            },
            {
                date: '2018-04-10',
                text: '營業收入公告 ( 107年3月份 )',
                url: '../upfile/ctxt_433.pdf'
            },
            {
                date: '2018-03-13',
                text: '本公司受邀參加美林舉辦之「2018 Asia Pacific TMT Conference」投資論壇',
                url: '../upfile/ctxt_432.pdf'
            },
            {
                date: '2018-03-06',
                text: '營業收入公告 ( 107年2月份 )',
                url: '../upfile/ctxt_430.pdf'
            },
            {
                date: '2018-02-09',
                text: '營業收入公告 ( 107年1月份 )',
                url: '../upfile/ctxt_429.pdf'
            },
            {
                date: '2018-01-30',
                text: '本公司將在香港舉辦海外法人說明會',
                url: '../upfile/ctxt_428.pdf'
            },
            {
                date: '2018-01-09',
                text: '營業收入公告 ( 106年12月份 )',
                url: '../upfile/ctxt_427.pdf'
            },
        ]

        return { Lists }
    }
}
</script>