<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2019-12-31',
                text: '公司組織及經營團隊',
                url: '../upfile/ctxt_503.pdf'
            },
            {
                date: '2019-12-31',
                text: '公司治理運作情形',
                url: '../upfile/ctxt_502.pdf'
            },
            {
                date: '2019-12-31',
                text: '落實誠信經營情形',
                url: '../upfile/ctxt_501.pdf'
            },
            {
                date: '2019-12-31',
                text: '獨立董事與內部稽核主管及會計師之溝通情形',
                url: '../upfile/ctxt_500.pdf'
            },
            {
                date: '2019-05-28',
                text: '公司前十大股東',
                url: '../upfile/ctxt_498.pdf'
            },
        ]

        return { Lists }
    }
}
</script>