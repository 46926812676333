import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import CharitableFoundation from "../views/CharitableFoundation.vue"
import Excellent from "../views/about/Excellent.vue"
import Principle from "../views/about/Principle.vue"
import Brands from "../views/about/Brands.vue"
import History from "../views/about/History.vue"
import CEO from "../views/about/CEO.vue"
import IR from '../views/IR.vue'
import CorporateGov from "../views/ir/CorporateGov.vue"
import Financials from "../views/ir/Financials.vue"
import Shareholders from "../views/ir/Shareholders.vue"
import CSR from '../views/CSR.vue'
import News from '../views/News.vue'
import JoinTTFB from '../views/JoinTTFB.vue'
import News2021 from "../views/News2021.vue"


const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/about",
        name: "About",
        component: About,
        redirect: "/about/excellent",
        children: [
            {
                path: "excellent",
                component: Excellent
            },
            {
                path: "principle",
                component: Principle
            },
            {
                path: "brands",
                component: Brands
            },
            {
                path: "history",
                component: History
            },
            {
                path: "ceo",
                component: CEO
            }
        ]
    },
    {
        path: "/ir",
        name: "IR",
        component: IR,
        redirect: "/ir/corporate-gov",
        children: [
            {
                path: "corporate-gov",
                component: CorporateGov
            },
            {
                path: "financials",
                component: Financials
            },
            {
                path: "shareholders",
                component: Shareholders
            }
        ]
    },
    {
        path: "/csr",
        name: "CSR",
        component: CSR
    },
    {
        path: "/news",
        name: "News",
        component: News
    },
    {
        path: "/jointtfb",
        name: "JoinTTFB",
        component: JoinTTFB
    },
    {
        path: "/foundation",
        name: "CharitableFoundation",
        component: CharitableFoundation
    },
    {
        path: "/news2021",
        name: "News2021",
        component: News2021
    },
    // { path: "/:pathMatch(.*)*", redirect: "/" }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
        return { top: 0 }
    }
})

export default router
