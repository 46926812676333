<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2012-08-29',
                text: '瓦城泰統上櫃掛牌前業績發表會影音檔',
                url: 'https://www.youtube.com/watch?v=UvWKPUutU5c&ab_channel=TTFB01'
            },
            {
                date: '2012-03-09',
                text: '瓦城泰統股份有限公司董事會議事規範',
                url: '../upfile/ctxt_131.pdf'
            },
            {
                date: '2012-01-01',
                text: '董事會成員',
                url: '../upfile/ctxt_117.pdf'
            },
            {
                date: '2012-01-01',
                text: '瓦城泰統股份有限公司股東會議事規則',
                url: '../upfile/ctxt_130.pdf'
            },
            {
                date: '2012-01-01',
                text: '為強化公司治理及健全董事會運作，選任三席獨立董事，並建立「獨立董事之職責範疇規則」',
                url: '../upfile/ctxt_118.pdf'
            },
            {
                date: '2012-01-01',
                text: '由三席獨立董事組成審計委員會，並建立「審計委員會組織規程」',
                url: '../upfile/ctxt_119.pdf'
            },
            {
                date: '2012-01-01',
                text: '設置薪酬委員會強化董事會職能，並建立「薪資報酬委員會組織規程」',
                url: '../upfile/ctxt_120.pdf'
            },
            {
                date: '2012-01-01',
                text: '本公司基於公平、誠實、守信、透明原則從事商業活動，為落實誠信經營 政策，並積極防範不誠信行為，依「上市上櫃公司誠信經營守則」訂定「誠信經營守則」',
                url: '../upfile/ctxt_121.pdf'
            },
            {
                date: '2012-01-01',
                text: '內部稽核之組織架構及運作',
                url: '../upfile/ctxt_123.pdf'
            },
        ]

        return { Lists }
    }
}
</script>