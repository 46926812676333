<template>
<div class="w-900 mx-auto mb-6">
    <img src="@/assets/about_t5.jpg">
    <div class="flex">
        <div class="w-full">
            <h1 class="my-2">「傳承東方料理的美味」</h1>
            <p>這是我三十多年來，天天都在努力做的同一件事。</p>
            <h1 class="mb-2 mt-6">「只要秉持著熱誠的心，我相信再困難的事情都可以做到」</h1>
            <p>我希望集團的每一位同仁，都能對自身有發自內心的敬重與認同感，<br>提供給同仁的不只是一份薪水，而是一份自信，<br>一份蹲了三十年馬步後，開始施展拳腳的自信丰采！</p>
            <p class="mt-3 mb-8">我們將掌握「高FEST值」抓住消費者的胃和心，全面提升顧客滿意度，<br>以紮實的基本功結合科技管理與東方料理，一步步穩健發展，<br>有一天，瓦城泰統將會成為全球最大的東方餐飲連鎖集團！</p>
            <img src="@/assets/about_t5b.jpg">
        </div>
        <div class="flex-none">
            <img src="@/assets/about_t5a.jpg">
        </div>
    </div>
</div>
</template>

<script>
export default {}
</script>