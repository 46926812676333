<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2012-12-21',
                text: '【網路新聞】瓦城推出創意尾牙春酒專案│中央日報',
                url: 'https://news.sina.com.tw/article/20121221/8612144.html'
            },
            {
                date: '2012-12-10',
                text: '【網路新聞】瓦城11月營收年成長5% 跨年、尾牙活動將掀業績高潮│鉅亨網',
                url: 'https://news.cnyes.com/Content/20121210/KFOHN498DWBPA.shtml?c=tw_stock'
            },
            {
                date: '2012-11-12',
                text: '【人物專訪】徐承義︰廚藝如武藝 萬事先蹲馬步起│自由時報',
                url: '../upfile/ctxt_178.pdf'
            },
            {
                date: '2012-10-01',
                text: '【雜誌報導】瓦城泰統集團董事長徐承義 通過3修煉，小店晉身上櫃集團│Career145期',
                url: '../upfile/ctxt_176.pdf'
            },
            {
                date: '2012-09-20',
                text: '【雜誌報導】密碼食代 瓦城泰統董事長徐承義│壹週刊591期',
                url: '../upfile/ctxt_175.pdf'
            },
            {
                date: '2012-09-18',
                text: '【網路新聞】瓦城亮眼上櫃 253元收盤│中國時報',
                url: 'http://money.chinatimes.com/news/news-content.aspx?id=20120918000670&cid=1212'
            },
            {
                date: '2012-09-17',
                text: '【影音新聞】瓦城上櫃 開盤大漲57元│民視新聞',
                url: 'https://tw.news.yahoo.com/video/%E7%93%A6%E5%9F%8E%E4%B8%8A%E6%AB%83-%E9%96%8B%E7%9B%A4%E5%A4%A7%E6%BC%B257%E5%85%83-042612674.html'
            },
            {
                date: '2012-09-17',
                text: '【網路新聞】瓦城泰統歡慶上櫃 4人同行送月亮蝦餅│欣傳媒',
                url: 'http://news.xinmedia.com/news_article.aspx?newsid=154780&type=0'
            },
            {
                date: '2012-09-17',
                text: '【網路新聞】瓦城上櫃 明年兩岸目標70家│中央社',
                url: 'http://www.cnabc.com/ShowNews/Detail.aspx?type=classify&category=aALL&id=201209170256'
            },
            {
                date: '2012-09-17',
                text: '【網路新聞】每年推新品牌！瓦城徐承義：明年Q1開出新品牌新菜系│鉅亨網',
                url: 'https://news.cnyes.com/content/20120917/KFMQU2YBCDY7V.shtml?c=pic'
            },

            {
                date: '2012-09-17',
                text: '【網路新聞】瓦城上櫃 餐飲族爆香│經濟日報',
                url: 'https://udn.com/news/e404?yhaos'
            },
            {
                date: '2012-09-13',
                text: '【雜誌報導】五年學成的廚藝，瓦城只要一年│財訊407期',
                url: '../upfile/ctxt_168.pdf'
            },
            {
                date: '2012-09-04',
                text: '【雜誌報導】2012預見服務大趨勢國際論壇│遠見雜誌315期',
                url: '../upfile/ctxt_167.pdf'
            },
            {
                date: '2012-09-01',
                text: '【人物專訪】瓦城董事長徐承義 20年跆拳魂 痛楚中磨練心志│蘋果日報',
                url: 'https://tw.appledaily.com/finance/20120901/NHGI457X5ZCYA2Q3JMV4SDBS7E/'
            },
            {
                date: '2012-08-31',
                text: '【影音新聞】瓦城泰統集團 預計9月17日上櫃│聯合影音網',
                url: 'http://video.udn.com/video/Item/ItemPage.do?sno=3-2B4-233-2B3d31303-2B3-2B4-233-2F3c333-2B4-233-2B'
            },
            {
                date: '2012-08-30',
                text: '【網路新聞】多品牌多市場 徐承義：瓦城2年內開百家店 10年後擁20個品牌│鉅亨網',
                url: 'https://news.cnyes.com/Content/20120830/KFM85WR9WHYPM.shtml?c=headline_channel'
            },
            {
                date: '2012-08-30',
                text: '【人物專訪】天生創業家 徐承義沒虧過錢│經濟日報',
                url: 'https://udn.com/news/e404?yhaos'
            },
            {
                date: '2012-08-27',
                text: '【人物專訪】徐承義：瓦城IPO問鼎東方爐炒連鎖餐飲龍頭 東方料理飄香│工商時報',
                url: '../upfile/ctxt_160.pdf'
            },
            {
                date: '2012-08-20',
                text: '【影音新聞】刀工.翻鍋11關卡 泰菜廚師大考驗│華視新聞',
                url: 'http://news.cts.com.tw/cts/life/201208/201208201077171.html'
            },
            {
                date: '2012-08-08',
                text: '【雜誌報導】瓦城董事長徐承義 抬拳式管理 挑戰餐飲業股王│Career職場情報誌436期',
                url: '../upfile/ctxt_155.pdf'
            },

            {
                date: '2012-08-04',
                text: '【網路新聞】瓦城徐承義：講求堅忍謙恭 廚藝、經營要讓顧客滿意│鉅亨網',
                url: 'https://news.cnyes.com/content/20120804/KFM2EZP5EIYZ2.shtml?c=pic'
            },
            {
                date: '2012-07-03',
                text: '【財經新聞】瓦城衝展店 明年登陸│經濟日報',
                url: '../upfile/ctxt_147.pdf'
            },
            {
                date: '2012-07-03',
                text: '【網路新聞】瓦城董事長徐承義：做菜像練武 要堅持│經濟日報',
                url: 'https://udn.com/news/e404?yhaos'
            },
            {
                date: '2012-06-29',
                text: '【影音新聞】餐飲股后瓦城董座徐承義 武道經營術產業脈動│非凡新聞',
                url: 'https://www.youtube.com/watch?v=epsDhw6uyEY&ab_channel=mymy0093'
            },
            {
                date: '2012-06-15',
                text: '	【雜誌報導】瓦城〉每10天派出神祕客突擊檢查│遠見雜誌6月服務專刊',
                url: '../upfile/ctxt_141.pdf'
            },
            {
                date: '2012-06-11',
                text: '【網路新聞】興櫃股王瓦城 通過上櫃審議 最快8月掛牌 全年拚開50家店│鉅亨網',
                url: 'https://news.cnyes.com/Content/20120611/KFKX9FD4ZHKIZ.shtml'
            },
            {
                date: '2012-06-08',
                text: '【網路新聞】瓦城5月營收年增逾3成 創歷史次高 全年店數衝50家│鉅亨網',
                url: 'https://news.cnyes.com/Content/20120608/KFKWMC38D8U5R.shtml'
            },
            {
                date: '2012-05-11',
                text: '【網路新聞】有備而來！ 4字堅持打造餐飲王國│TVBS',
                url: 'https://www.tvbs.com.tw/NEWS/NEWS_LIST.asp?no=anita198920120511153534'
            },
            {
                date: '2012-05-02',
                text: '【網路新聞】瓦城首季EPS 2.29元新高 今年新增店14家 估賺逾1股本│鉅亨網',
                url: 'https://news.cnyes.com/news/id/2226777'
            },
            {
                date: '2012-04-23',
                text: '【網路新聞】瓦城配發8元現金股利 Q3上櫃 今開新竹巨城店 全年衝50家│鉅亨網',
                url: 'https://news.cnyes.com/Content/20120423/KFJSY6T7EMD7.shtml?c=pre'
            },
            {
                date: '2012-04-13',
                text: '【網路新聞】瓦城送件申請上櫃 Q3掛牌 電價漲 可自行吸收│鉅亨網',
                url: 'https://news.cnyes.com/Content/20120413/KFJQSYMN21576.shtml?c=eme'
            },
            {
                date: '2012-04-12',
                text: '【網路新聞】安心、瓦城Q1亮眼 續拚展店│工商時報',
                url: 'https://tw.news.yahoo.com/%E5%AE%89%E5%BF%83-%E7%93%A6%E5%9F%8Eq1%E4%BA%AE%E7%9C%BC-%E7%BA%8C%E6%8B%9A%E5%B1%95%E5%BA%97-213000832.html'
            },
            {
                date: '2012-04-05',
                text: '【網路新聞】瓦城去年獲利創新高，EPS 9.45元│精實新聞',
                url: 'https://www.moneydj.com/KMDJ/News/NewsViewer.aspx?a=7016ab1c-9276-4c6c-8892-e518b6fa2edc'
            },
            {
                date: '2012-04-03',
                text: '【網路新聞】瓦城去年全年每股稅後純益9.42元│聯合新聞網',
                url: 'https://udn.com/news/e404?yhaos'
            },
            {
                date: '2012-04-02',
                text: '【網路新聞】瓦城獲利吃香！去年賺近1個股本│鉅亨網',
                url: 'https://news.cnyes.com/news/id/2209556'
            },
            {
                date: '2012-03-16',
                text: '【雜誌報導】瓦城：高FEST值 抓住消費者的胃和心 結合科技管理與東方料理│遠見雜誌3月號',
                url: '../upfile/ctxt_108.pdf'
            },
            {
                date: '2012-02-15',
                text: '【網路新聞】泰國料理第1品牌 瓦城今年展店逾18家│蘋果日報',
                url: 'http://tw.nextmedia.com/applenews/article/art_id/34024294/IssueID/20120215'
            },
            {
                date: '2012-02-14',
                text: '【網路新聞】瓦城吃香！1月業績年增近倍創新高 龍年開春漲逾2成│鉅亨網',
                url: 'https://news.cnyes.com/Content/20120214/KFIK5JMAX1LCI.shtml'
            },
            {
                date: '2012-02-10',
                text: '【網路新聞】瓦城泰統1月非合併營收2.04億元年增92.83% 1— 1月達2.04億元│鉅亨網',
                url: 'https://news.cnyes.com/news/id/2453659'
            },
        ]

        return { Lists }
    }
}
</script>