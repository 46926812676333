<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2020-06-23',
                text: '109年股東會議事錄',
                url: '../upfile/ctxt_526.pdf'
            },
            {
                date: '2020-06-12',
                text: '	108年年報',
                url: '../upfile/ctxt_525.pdf'
            },
            {
                date: '2020-06-12',
                text: '	109年股東會議事手冊',
                url: '../upfile/ctxt_524.pdf'
            },
            {
                date: '2020-05-22',
                text: '109年股東會開會通知',
                url: '../upfile/ctxt_523.pdf'
            },
        ]

        return { Lists }
    }
}
</script>