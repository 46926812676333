<template>
<footer class="">
    <div class="flex items-center justify-center mb-6">
        <img class="w-900 p-4" src="@/assets/logos.png" />
    </div >
    <!-- <div class="flex items-center justify-center space-x-6 mb-6">
        <span>
            <a href="https://www.thaitown.com.tw/" target="_blank">
                <img src="@/assets/thai_town.jpg">
            </a>
        </span>

    </div>
    <div class="flex items-center justify-center space-x-6 mb-6">

        <span>
            <a href="https://www.verythai.com.tw/" target="_blank">
                <img src="@/assets/verythai.jpg">
            </a>
        </span>
        <span>
            <a href="https://www.1010restaurant.com/" target="_blank">
                <img src="@/assets/1010.jpg">
            </a>
        </span>

        <span>
            <a href="https://ricebar.com.tw/" target="_blank">
                <img src="@/assets/shann_rice.jpg">
            </a>
        </span>
    </div>
    <div class="flex items-center justify-center space-x-6 mb-6">
        <span>
            <a href="https://www.facebook.com/YABIKITCHEN/" target="_blank">
                <img src="@/assets/yabi.jpg">
            </a>
        </span>
        <span>
            <a href="https://bheartnoodles.com/" target="_blank">
                <img src="@/assets/bighart.jpg">
            </a>
        </span>
        <span>
            <a href="https://www.facebook.com/yuethaigrill/" target="_blank">
                <img src="@/assets/taibbq.jpg">
            </a>
        </span>

        <span>
            <a href="https://mp.weixin.qq.com/s/7WC9c8Akgq-uG5_anJ5w6w?" target="_blank">
                <img src="@/assets/ten.jpg">
            </a>
        </span>
        <span>
            <a href="https://mp.weixin.qq.com/s/7WC9c8Akgq-uG5_anJ5w6w?" target="_blank">
                <img class="h-14" src="@/assets/thediner.png">
            </a>
        </span>
        <span>
            <a href="https://mp.weixin.qq.com/s/7WC9c8Akgq-uG5_anJ5w6w?" target="_blank">
                <img class="h-14"  src="@/assets/bobo.png">
            </a>
        </span>
    </div> -->
    <div class="bg-gray-200 w-900 mx-auto">
        <div class="flex justify-center items-center px-4 py-2 font-semibold text-gray-500">
            <p>© 瓦城泰統集團 ALL RIGHTS RESERVED &emsp; 客服信箱：<a class="hover:underline" href="mailto:service@ttfb.com" target="_blank">service@ttfb.com</a> &emsp; 客服專線：0800-086-680(服務時間：週一~五 9:30~18:00)</p>
        </div>
    </div>
</footer>
</template>

<script>
export default {}
</script>