<template>
<ir-nav class="flex justify-center">
    <ul class="text-sm text-gray-500 my-3 space-x-8 flex items-center">
        <li>
            <router-link to="/ir/corporate-gov">公司治理</router-link>
        </li>
        <li>
            <router-link to="/ir/financials">財務資訊</router-link>
        </li>
        <li>
            <a href="https://mops.twse.com.tw/mops/web/t51sb10_q1">重大訊息公告</a>
        </li>
        <li>
            <router-link to="/ir/shareholders">股東服務</router-link>
        </li>
    </ul>
</ir-nav>
<hr class="w-900 mx-auto border-bgnav opacity-50">
<router-view />
</template>

<script>
export default {}
</script>