<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2020-11-13',
                text: '109 年 第三季 財務報告書',
                url: '../upfile/ctxt_548.pdf'
            },
            {
                date: '2020-08-14',
                text: '109 年 第二季 財務報告書',
                url: '../upfile/ctxt_547.pdf'
            },
            {
                date: '2020-05-15',
                text: '109 年 第一季 財務報告書',
                url: '../upfile/ctxt_546.pdf'
            },
            {
                date: '2020-03-31',
                text: '108 年度財務報告書 -個體',
                url: '../upfile/ctxt_545.pdf'
            },
            {
                date: '2020-03-31',
                text: '108 年度財務報告書 -合併',
                url: '../upfile/ctxt_544.pdf'
            },
            {
                date: '2020-12-10',
                text: '營業收入公告 ( 109年11月份 )',
                url: '../upfile/ctxt_530.pdf'
            },
            {
                date: '2020-11-10',
                text: '營業收入公告 ( 109年10月份 )',
                url: '../upfile/ctxt_529.pdf'
            },
            {
                date: '2020-10-12',
                text: '營業收入公告 ( 109年09月份 )',
                url: '../upfile/ctxt_528.pdf'
            },
            {
                date: '2020-09-09',
                text: '營業收入公告 ( 109年08月份 )',
                url: '../upfile/ctxt_522.pdf'
            },
            {
                date: '2020-08-10',
                text: '營業收入公告 ( 109年07月份 )',
                url: '../upfile/ctxt_521.pdf'
            },
            {
                date: '2020-07-10',
                text: '營業收入公告 ( 109年06月份 )',
                url: '../upfile/ctxt_520.pdf'
            },
            {
                date: '2020-06-05',
                text: '營業收入公告 ( 109年05月份 )',
                url: '../upfile/ctxt_519.pdf'
            },
            {
                date: '2020-05-11',
                text: '營業收入公告 ( 109年04月份 )',
                url: '../upfile/ctxt_518.pdf'
            },
            {
                date: '2020-04-10',
                text: '營業收入公告 ( 109年03月份 )',
                url: '../upfile/ctxt_517.pdf'
            },
            {
                date: '2020-03-10',
                text: '營業收入公告 ( 109年02月份 )',
                url: '../upfile/ctxt_516.pdf'
            },
            {
                date: '2020-02-10',
                text: '營業收入公告 ( 109年01月份 )',
                url: '../upfile/ctxt_515.pdf'
            },
            {
                date: '2020-01-08',
                text: '營業收入公告 ( 108年12月份 )',
                url: '../upfile/ctxt_514.pdf'
            },
        ]

        return { Lists }
    }
}
</script>