<template>
<div class="w-900 mx-auto mt-12 mb-10">
    <img src="@/assets/foundation_t1.jpg">
    <div class="w-full">
        <h2 class="font-bold text-red-600 text-xl mt-6">關於基金會</h2>
        <h2 class="font-bold text-title1 mt-2 mb-4">牽起一份感動，傳遞溫暖的愛與希望</h2>
        <p class="mb-3">為人們牽動一次又一次的緣份，用溫暖來感動人心，真正落實在需要關注的角落，是瓦城泰統慈善基金會長期行善的目標。</p>
        <p class="mb-3">於2016年成立起，瓦城慈善基金會即向有需求的家庭伸出援手，給予部份弱勢家庭中的兒少直接或實質的協助，讓受幫助的家庭重拾原本暫缺的笑容，我們獲得的回饋是無與倫比的感動，這也是瓦城泰統持續行善的動力。</p>
        <p>在關懷社會的同時，瓦城泰統自律嚴謹實踐企業社會責任，延伸一步一腳印的企業文化精神，在朝全球化國際舞台闊步時、亦以成為一個綠色企業而努力，為下一代、為環境、為土地、為地球，永續奉獻瓦城泰統所能實踐的力量，讓這個世界的愛與希望永不熄滅。</p>
        <!-- --->
        <hr class="my-5 border-title1 opacity-50">
        <!-- --->
        <div class="flex justify-between">
            <div>
                <h2 class="font-bold text-red-600 text-xl mb-4">基金會組織架構</h2>
                <div class="flex">
                    <p class="font-semibold">董事長：</p>
                    <p>徐承義</p>
                </div>
                <div class="flex">
                    <p class="font-semibold">&emsp;董事：</p>
                    <p>
                        廖文旭&emsp;利平會計師事務所會計師<br>
                        江偉儀&emsp;瓦城股份有限公司執行董事<br>
                        包益民&emsp;包氏國際有限公司負責人<br>
                        孫大龍&emsp;律儀聯合律師事務所律師<br>
                        石世賢&emsp;亞太聯合會計師事務所合夥會計師
                    </p>
                </div>
                <div class="flex">
                    <p class="font-semibold">執行長：</p>
                    <p>吳丹鳳</p>
                </div>
            </div>
            <div class="flex-none">
                <img class="h-56 mr-16" src="@/assets/foundation_t2.png">
            </div>
        </div>
        <!-- --->
        <hr class="my-5 border-title1 opacity-50">
        <!-- --->
        <h2 class="font-bold text-red-600 text-xl mt-6 mb-4">執行長理念</h2>
        <p class="mb-3">吳丹鳳執行長回顧當初成立基金會的初衷是根源於「愛」和「回饋」，愛是延續瓦城泰統集團一直以來給予顧客的真誠與真心，回饋則是我們一直很感謝大眾給予肯定支持瓦城成長至今。經營企業的目標不單只是在獲利，而是當我們有能貢獻力量時，我們透過基金會運用更有效益的管道和方式，提供協助給有需要的對象，成為支持社會的堅定能量。此外，回饋土地及環境更是企業責無旁貸的使命，瓦城泰統將透過基金會為媒介，貢獻我們的經驗與能力於永續環境，為我們的下一代和綠色地球而努力。</p>
        <p>「瓦城泰統慈善基金會」是一座讓人們感受到愛的溫暖平臺，基金會現階段除獨家贊助臺北榮總紅鼻子醫生計畫之外，也持續資助弱勢學生們就學。基金會凝聚了來自集團3000多位同仁們的大愛小愛，使它們變成一股強大的正善能量，一步一腳印的替社會增溫、讓人們感動。</p>
        <hr class="my-5 border-title1 opacity-50">
        <h2 class="font-bold text-red-600 text-xl mt-6 mb-4">基金會會務</h2>
        <p class="mb-3">「瓦城泰統慈善基金會」是集團投入公益事業的主要單位，成立初期基金會主要會務包括：贊助臺北榮總重症兒童病房「紅鼻子醫生長期醫護關懷巡演計畫」等。未來將落實更多回饋土地、關懷社會使命之慈善行動，以延續瓦城泰統集團的溫暖、溫度與溫心！</p>
        <img class="mt-4" src="@/assets/foundation_t3.jpg">
        <!-- --->
        <hr class="my-5 border-title1 opacity-50">
        <!-- --->
        <h2 class="font-bold text-red-600 text-xl mt-6 mb-4">基金會事紀</h2>
        <table class="w-900 text-xs table-fixed">
            <thead class="bg-gray-500 text-white font-bold">
                <tr class="h-8">
                    <th class="w-2/12">日 期</th>
                    <th class="w-8/12">標 題</th>
                    <th class="w-2/12">附 檔</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(List, index) in Lists" :key="index" v-once class="h-8 even:bg-red-100">
                    <td class="text-center">{{ List.date }}</td>
                    <td class="">{{ List.text }}</td>
                    <td class="text-center">
                        <a :href="List.url" target="_blink">
                            <DownloadIcon class="h-5 text-red-700 mx-auto cursor-pointer transition duration-300 ease-in-out transform hover:translate-y-1" />
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- --->
        <hr class="mb-5 mt-10 border-title1 opacity-50">
        <!-- --->
        <h2 class="font-bold text-red-600 text-xl mt-6 mb-4">瓦城泰統慈善基金會影音頻道</h2>
        <p>【瓦城首善獻溫心】讓紅鼻子施展魔力，病房瞬間笑容處處開！<br>每個星期四，當紅鼻子醫生來的時候，就是臺北榮總病房歡笑聲不斷的一天！</p>
        <a href="https://www.youtube.com/channel/UC20P1MsD0REqK3TyY-GdWnA" target="_blank">
            <img src="@/assets/youtube_1.jpg">
        </a>
    </div>
</div>
</template>

<script>
import { DownloadIcon } from '@heroicons/vue/outline'
export default {
    components: { DownloadIcon },

    setup() {
        const Lists = [
            {
                date: '2018-04-02',
                text: '【瓦城孝親獎】起跑~ 4/1-6/30募集三代同堂孝親家庭，一起用家庭故事溫暖社會。活動詳情',
                url: 'https://www.hondao.org.tw/activity_page/2018family/index.htm'
            },
            {
                date: '2016-10-06',
                text: '瓦城泰統獻首善~「紅鼻子醫生長期醫護關懷巡演計畫」於臺北榮總正式開演',
                url: './upfile/F20161006.pdf'
            },
            {
                date: '2016-10-08',
                text: '[影片] 圓一個大大的微笑~瓦城泰統贊助紅鼻子醫生之北榮巡演紀實',
                url: 'https://www.youtube.com/watch?v=vTcrw1-yWxg&ab_channel=TTFBFoundation%E7%93%A6%E5%9F%8E%E6%B3%B0%E7%B5%B1%E6%85%88%E5%96%84%E5%9F%BA%E9%87%91%E6%9C%83'
            },
            {
                date: '2016-11-17',
                text: '瓦城泰統首善獻愛- 贊助臺北榮總重症兒童病房「紅鼻子醫生長期醫護關懷巡演計畫」記者會紀實',
                url: './upfile/F20161117.pdf'
            },
        ]

        return { Lists }
    }
}
</script>