<template>
<div class="w-900 mx-auto mb-10">
    <img src="@/assets/about_t3.jpg">
    <div class="w-3/4">
        <img src="@/assets/about_t3a.jpg">
        <h1 class="my-2">全亞洲千萬人熱愛的泰國料理品牌</h1>
        <p class="mb-3">1990年創立，現已是全台最大的泰式連鎖餐飲第一 品牌，以高品質的道地美味、親切熱誠的服務，及溫馨舒適的環境，引領全台泰式餐飲風潮。</p>
        <p class="mb-3">累積30年東方餐飲連鎖經營實力，瓦城團隊有紮實純熟的手藝，創造出色、香、味、形俱全的道地饗宴，堅持精準掌控每一道料理，從食材挑選、廚藝烹調到服務上桌，讓您在每間【瓦城】、每一天都 可享受到最高品質的泰式佳餚。</p>
        <p class="mb-3">2013年進軍大陸市場，已於上海、蘇州開設海外分店，深受各界朋友的喜愛，是全亞洲千萬人熱愛的泰國料理品牌!</p>
        <p>瓦城官網：<a class="hover:underline" href="https://www.thaitown.com.tw" target="_blank">https://www.thaitown.com.tw</a></p>
        <p class="mb-10">瓦城臉書：<a class="hover:underline" href="https://www.facebook.com/thaitowncuisine" target="_blank">https://www.facebook.com/thaitowncuisine</a></p>
        <!-- -->
        <img src="@/assets/about_t3b.jpg">
        <h1 class="my-2">泰式美食享樂地</h1>
        <p class="mb-3">成立於1995年，由都會文化應醞而生，為都會流行餐飲的新興指標。</p>
        <p class="mb-3">【非常泰】在市場上率先結合辛辣美食與聲光音樂享受，首創獨特的飲食享樂型態，創造泰國料理的嶄新現代風貌。</p>
        <p>非常泰官網：<a class="hover:underline" href="https://www.verythai.com.tw" target="_blank">https://www.verythai.com.tw</a></p>
        <p class="mb-10">非常泰臉書：<a class="hover:underline" href="https://www.facebook.com/verythai" target="_blank">https://www.facebook.com/verythai</a></p>
        <!-- -->
        <img src="@/assets/about_t3c.jpg">
        <h1 class="my-2">十全十美色湘味濃</h1>
        <p class="mb-3">成立於2006年，為全台最大的湘菜餐廳第一品牌，【1010湘】的廚房團隊，遠赴上海集中密訓，把傳統湖南家鄉菜，以現代美食觀感重新詮釋，將湖南菜千年的美味、與湘菜文化的精隨，呈現給所有喜愛美食的消費者認識與體驗。</p>
        <p class="mb-3">首度將「東方爐炒連鎖化系統」運用於中國八大菜系中烹調技術最繁複菜系，成功將湘菜進行標準化與連鎖化，具有里程碑意義，將累積了數千年的東方飲食文化與廚藝傳承保留下來。</p>
        <p class="mb-3">2013年榮獲「The Miele Guide 頂級亞洲美食評鑑指南」台灣區最家餐廳，深受美食家、知名部落客等美食饕客及各界名人喜愛，更分別於2017、2019年兩度榮獲「台灣 服務業奧斯卡獎」美譽的《遠見》服務業大調查首獎。</p>
        <p>1010湘官網：<a class="hover:underline" href="https://www.1010restaurant.com" target="_blank">https://www.1010restaurant.com</a></p>
        <p class="mb-10">1010湘臉書：<a class="hover:underline" href="https://www.facebook.com/1010hunancuisine" target="_blank">https://www.facebook.com/1010hunancuisine</a></p>
        <!-- -->
        <img src="@/assets/about_t3d.jpg">
        <h1 class="my-3">完美口感的頂級湘菜</h1>
        <p class="mb-3">以湘菜+BISTRO為概念的【十食湘BISTRO】2014年7月 第一家分店在上海獨家登場。</p>
        <p class="mb-3">【十食湘BISTRO】結合時尚都會的用餐環境，薈萃傳統與現代風貌，在烹調上講究原料的入味，擅用臘、熏、 煨、蒸、燉、炸、炒等技法，並引進歐美以酒佐餐的文化，以⻄式葡萄酒搭配中式湘菜，【十食湘BISTRO】完美口感的頂級湘菜，獻給現代人層次細緻、風味無窮的生活享受，2018年獲選攜程網「全球餐廳精選榜」之上海城市推薦餐廳。</p>
        <p class="mb-10">十食湘BISTRO微信：<a class="hover:underline" href="https://mp.weixin.qq.com/s/7WC9c8Akgq-uG5_anJ5w6w" target="_blank">https://mp.weixin.qq.com/s/7WC9c8Akgq-uG5_anJ5w6w</a></p>
        <!-- -->
        <img src="@/assets/about_t3e.jpg">
        <h1 class="my-3">讓您開胃又暖心的美味，大大滿足！大大開心！</h1>
        <p class="mb-3">瓦城泰統集團第四品牌【大心】，全球首店於2014年5月誕生，陸續成功進駐台北、台中、台南、高雄、嘉義、宜 蘭。承襲瓦城多年泰菜精湛手藝與美味配方，運用各式泰國天然香料，嶄新演繹泰國麵食創造「大大滿足!大大開 心! 」的美味享受，營造自在愉悅又兼具新潮的餐飲氣氛。</p>
        <p>大心官網：<a class="hover:underline" href="https://bheartnoodles.com/" target="_blank">https://bheartnoodles.com/</a></p>
        <p class="mb-10">大心臉書：<a class="hover:underline" href="https://www.facebook.com/BHeartNoodles/" target="_blank">https://www.facebook.com/BHeartNoodles/</a></p>
        <!-- -->
        <img src="@/assets/about_t3f.jpg">
        <h1 class="my-3">最令人飯癮的跨菜系中式天菜</h1>
        <p class="mb-3">2017年初，全球首店於台中登場。承襲瓦城泰統集團深厚的廚藝底蘊，完美集結川、台、粵等所有熱門中式人氣名菜，加上獨家配方，一餐即可全享「跨菜系中式料 理」，更以東方餐飲最重要的米食文化為核心，獨創全新的餐飲型態-RICE BAR，提供道道經典、道道下飯的多樣美味。</p>
        <p>時時香官網：<a class="hover:underline" href="https://ricebar.com.tw/" target="_blank">https://ricebar.com.tw/</a></p>
        <p class="mb-10">時時香臉書：<a class="hover:underline" href="https://www.facebook.com/RICEBARTW/" target="_blank">https://www.facebook.com/RICEBARTW/</a></p>
        <!-- -->
        <img src="@/assets/about_t3g.jpg">
        <h1 class="my-3">震撼靈魂的亞洲旅行風味</h1>
        <p class="mb-3">瓦城泰統集團主打南洋菜系的第七品牌【YABI KITCHEN】於2019年成立，承襲集團30年在東方餐飲領域的精湛廚藝，精選星、馬、泰、印度風味，以跨國界南洋餐飲帶給消費者全新的美食體驗。菜色不僅是南洋各地代表性及高人氣的經典招牌菜色，還具有「口味濃郁、層次豐富又下飯」的特色。</p>
        <p class="mb-10">YABI 臉書：<a class="hover:underline" href="https://www.facebook.com/YABIKITCHEN/" target="_blank">https://www.facebook.com/YABIKITCHEN/</a></p>
        <!-- -->
        <img src="@/assets/about_t3h.jpg">
        <h1 class="my-3">用泰式串燒享受最Chill的風味</h1>
        <p class="mb-3">延續對泰式料理的鑽研與熱情，瓦城泰統集團第八品牌【月月 泰BBQ】以泰式串燒BBQ與各種異國啤酒，打造獨特的新泰式料理品牌，最適合大口吃 肉、大口喝酒，放鬆談天，Chill到一個不行!</p>
        <p class="mb-10">月月 泰BBQ 臉書：<a class="hover:underline" href="https://www.facebook.com/yuethaigrill/" target="_blank">https://www.facebook.com/yuethaigrill/</a></p>
        <!-- -->
    </div>
</div>
</template>

<script>
export default {}
</script>