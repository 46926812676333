<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2015-09-10',
                text: '應櫃買中心邀請之業績發表會',
                url: 'https://mops.twse.com.tw/mops/web/t100sb07_1'
            },
            {
                date: '2015-06-15',
                text: '本公司-公司治理實務守則',
                url: '../upfile/ctxt_335.pdf'
            },
            {
                date: '2015-06-15',
                text: '修訂本公司-誠信經營守則',
                url: '../upfile/ctxt_334.pdf'
            },
            {
                date: '2015-06-15',
                text: '修訂本公司-企業社會責任實務守則',
                url: '../upfile/ctxt_333.pdf'
            },
        ]

        return { Lists }
    }
}
</script>