<template>
<aboutnav class="flex justify-center">
    <ul class="text-sm text-gray-500 my-3 space-x-8 flex items-center">
        <li>
            <router-link to="/about/excellent">傳承美味</router-link>
        </li>
        <li>
            <router-link to="/about/principle">堅信理念</router-link>
        </li>
        <li>
            <router-link to="/about/brands">事業版圖</router-link>
        </li>
        <li>
            <router-link to="/about/history">創立歷程</router-link>
        </li>
        <li>
            <router-link to="/about/ceo">董事長的話</router-link>
        </li>
    </ul>
</aboutnav>
<hr class="w-900 mx-auto border-bgnav opacity-50">
<router-view />
</template>

<script>
export default {}
</script>