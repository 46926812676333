<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2017-12-31',
                text: '106年第三季合併財報',
                url: '../upfile/ctxt_460.pdf'
            },
            {
                date: '2017-12-31',
                text: '106年第二季合併財報',
                url: '../upfile/ctxt_459.pdf'
            },
            {
                date: '2017-12-31',
                text: '106年第一季合併財報',
                url: '../upfile/ctxt_458.pdf'
            },
            {
                date: '2017-12-31',
                text: '105年度合併財報',
                url: '../upfile/ctxt_457.pdf'
            },
            {
                date: '2017-12-31',
                text: '105年度個體財報',
                url: '../upfile/ctxt_456.pdf'
            },
            {
                date: '2017-12-08',
                text: '營業收入公告 ( 106年11月份 )',
                url: '../upfile/ctxt_414.pdf'
            },
            {
                date: '2017-11-09',
                text: '營業收入公告 ( 106年10月份 )',
                url: '../upfile/ctxt_413.pdf'
            },
            {
                date: '2017-10-11',
                text: '營業收入公告 ( 106年09月份 )',
                url: '../upfile/ctxt_412.pdf'
            },
            {
                date: '2017-09-07',
                text: '營業收入公告 ( 106年08月份 )',
                url: '../upfile/ctxt_411.pdf'
            },
            {
                date: '2017-08-08',
                text: '營業收入公告 ( 106年07月份 )',
                url: '../upfile/ctxt_410.pdf'
            },
            {
                date: '2017-07-10',
                text: '營業收入公告 ( 106年06月份 )',
                url: '../upfile/ctxt_409.pdf'
            },
            {
                date: '2017-06-07',
                text: '營業收入公告 ( 106年05月份 )',
                url: '../upfile/ctxt_404.pdf'
            },
            {
                date: '2017-05-08',
                text: '營業收入公告 ( 106年04月份 )',
                url: '../upfile/ctxt_403.pdf'
            },
            {
                date: '2017-04-10',
                text: '營業收入公告 ( 106年03月份 )',
                url: '../upfile/ctxt_402.pdf'
            },
            {
                date: '2017-03-10',
                text: '營業收入公告 ( 106年02月份 )',
                url: '../upfile/ctxt_398.pdf'
            },
            {
                date: '2017-02-08',
                text: '營業收入公告 ( 106年01月份 )',
                url: '../upfile/ctxt_397.pdf'
            },
            {
                date: '2017-01-10',
                text: '營業收入公告 ( 105年12月份 )',
                url: '../upfile/ctxt_396.pdf'
            },
        ]

        return { Lists }
    }
}
</script>