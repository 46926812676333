<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2014-12-10',
                text: '營業收入公告 ( 103年11月份 )',
                url: '../upfile/ctxt_307.pdf'
            },
            {
                date: '2014-11-10',
                text: '營業收入公告 ( 103年10月份 )',
                url: '../upfile/ctxt_306.pdf'
            },
            {
                date: '2014-10-09',
                text: '營業收入公告 ( 103年09月份 )',
                url: '../upfile/ctxt_299.pdf'
            },
            {
                date: '2014-09-10',
                text: '營業收入公告 ( 103年08月份 )',
                url: '../upfile/ctxt_297.pdf'
            },
            {
                date: '2014-08-07',
                text: '營業收入公告 ( 103年07月份 )',
                url: '../upfile/ctxt_290.pdf'
            },
            {
                date: '2014-07-09',
                text: '營業收入公告 ( 103年06月份 )',
                url: '../upfile/ctxt_283.pdf'
            },
            {
                date: '2014-06-09',
                text: '營業收入公告 ( 103年05月份 )',
                url: '../upfile/ctxt_297.pdf'
            },
            {
                date: '2014-05-09',
                text: '營業收入公告 ( 103年04月份 )',
                url: '../upfile/ctxt_273.pdf'
            },
            {
                date: '2014-04-09',
                text: '營業收入公告 ( 103年03月份 )',
                url: '../upfile/ctxt_272.pdf'
            },
            {
                date: '2014-03-10',
                text: '營業收入公告 ( 103年02月份 )',
                url: '../upfile/ctxt_263.pdf'
            },
            {
                date: '2014-02-10',
                text: '營業收入公告 ( 103年01月份 )',
                url: '../upfile/ctxt_261.pdf'
            },
            {
                date: '2014-01-08',
                text: '營業收入公告 ( 102年12月份 )',
                url: '../upfile/ctxt_253.pdf'
            },
        ]

        return { Lists }
    }
}
</script>