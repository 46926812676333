<template>
<tr class="h-8 even:bg-bgtable">
    <td class="text-center">{{ date }}</td>
    <td class="pl-12">{{ text }}</td>
    <td class="text-center">
        <a :href="url" target="_blink">
            <DownloadIcon class="h-5 text-red-700 mx-auto cursor-pointer transition duration-300 ease-in-out transform hover:translate-y-1" />
        </a>
    </td>
</tr>
</template>

<script>
import { DownloadIcon } from '@heroicons/vue/outline'
export default {
    components: { DownloadIcon },
    props: {
        date: { type: String },
        text: { type: String },
        url: { type: String }
    }
    // props: ['data', 'text', 'url']
}
</script>
