<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2013-12-09',
                text: '【網路新聞】《業績-觀光》瓦城前11月營收破20億元，歷史新高│時報',
                url: 'http://money.chinatimes.com/news/news-content.aspx?id=20131209003362&cid=1204'
            },
            {
                date: '2013-12-09',
                text: '【網路新聞】瓦城11月營收年增逾40% 年底前總店數突破60家│鉅亨網',
                url: 'https://news.cnyes.com/news/id/1963794'
            },
            {
                date: '2013-11-12',
                text: '【新聞報導】瓦城 獲利創新高│工商時報',
                url: '../upfile/ctxt_247.pdf'
            },
            {
                date: '2013-11-11',
                text: '【網路新聞】瓦城Q3獲利創單季新高 前3季EPS 9.21元│鉅亨網',
                url: 'https://news.cnyes.com/news/id/1913796'
            },
            {
                date: '2013-11-11',
                text: '【網路新聞】瓦城前3季EPS9.21元　Q4營運動能續揚│NOWnews',
                url: 'https://www.nownews.com/n/2013/11/11/1016729'
            },
            {
                date: '2013-11-07',
                text: '【新聞報導】瓦城董事長-徐承義勇奪《安永企業家獎》年度大獎│工商時報',
                url: '../upfile/ctxt_244.pdf'
            },
            {
                date: '2013-10-18',
                text: '【新聞報導】瓦城下季再開新品牌 明年集團中國達10店│蘋果日報',
                url: '../upfile/ctxt_242.pdf'
            },
            {
                date: '2013-10-18',
                text: '【新聞報導】瓦城上海上菜 明年展店10家│工商時報',
                url: '../upfile/ctxt_239.pdf'
            },
            {
                date: '2013-10-18',
                text: '【新聞報導】瓦城 海外1號店登陸│經濟日報',
                url: '../upfile/ctxt_241.pdf'
            },
            {
                date: '2013-10-18',
                text: '【新聞報導】瓦城前進中國 下月上海設點│爽報',
                url: '../upfile/ctxt_240.pdf'
            },
            {
                date: '2013-10-17',
                text: '【網路新聞】瓦城11月上海飄香 預計明年底前大陸展店達10家│鉅亨網',
                url: 'https://news.cnyes.com/news/id/1866672'
            },
            {
                date: '2013-10-08',
                text: '【新聞報導】10月連開4分店 瓦城業績嗆辣│蘋果日報',
                url: '../upfile/ctxt_238.pdf'
            },
            {
                date: '2013-09-10',
                text: '【雜誌報導】客人教會我的服務│遠見服務專刊',
                url: '../upfile/ctxt_236.pdf'
            },
            {
                date: '2013-07-08',
                text: '【新聞報導】好熱！瓦城6月營收月增9.78%│聯合晚報',
                url: '../upfile/ctxt_231.pdf'
            },
            {
                date: '2013-06-11',
                text: '【網路新聞】瓦城5月營收年增18.4%　全年有望維持兩位數成長│ETtoday',
                url: 'https://finance.ettoday.net/news/220643?redirect=1'
            },
            {
                date: '2013-05-23',
                text: '【網路新聞】瓦城年底搶灘大陸市場│工商時報',
                url: '../upfile/ctxt_221.pdf'
            },
            {
                date: '2013-05-23',
                text: '【網路新聞】瓦城 搶灘上海南京│經濟日報',
                url: '../upfile/ctxt_220.pdf'
            },
            {
                date: '2013-05-10',
                text: '【網路新聞】瓦城雙利多加持　Q1成績泰亮眼│ETtoday',
                url: 'https://finance.ettoday.net/news/205033?redirect=1'
            },
            {
                date: '2013-05-10',
                text: '【網路新聞】首季每股獲利／瓦城2.86元 創歷史新高│經濟日報',
                url: '../upfile/ctxt_216.pdf'
            },
            {
                date: '2013-05-09',
                text: '網路新聞】瓦城Q1獲利0.66億創新高，EPS 2.86元│精實新聞',
                url: 'https://www.moneydj.com/KMDJ/News/NewsViewer.aspx?a=603e6820-a01a-4cbe-9046-5da8ba35e965'
            },
            {
                date: '2013-04-10',
                text: '【雜誌報導】瓦城 連牙籤規格都要展現品牌精神│遠見品牌專刊',
                url: '../upfile/ctxt_222.pdf'
            },
            {
                date: '2013-03-27',
                text: '【網路新聞】瓦城食力強 EPS衝上9.53元│工商時報',
                url: 'https://tw.news.yahoo.com/%E7%93%A6%E5%9F%8E%E9%A3%9F%E5%8A%9B%E5%BC%B7-eps%E8%A1%9D%E4%B8%8A9-53%E5%85%83-213000194--finance.html'
            },
            {
                date: '2013-03-26',
                text: '【網路新聞】瓦城去年EPS9.53元　擬配發現金股利8.1元│NOWnews',
                url: 'https://www.nownews.com/2013/03/26/11490-2918707.htm'
            },
            {
                date: '2013-03-26',
                text: '【網路新聞】瓦城去年EPS 9.53元創新高 配息8.3元 殖利率逾4%│鉅亨網',
                url: 'https://news.cnyes.com/news/id/1741536'
            },
            {
                date: '2013-03-07',
                text: '【網路新聞】2月營收／瓦城績增41% 登高峰│經濟日報',
                url: '../upfile/ctxt_197.pdf'
            },
            {
                date: '2013-01-07',
                text: '【網路新聞】瓦城Q4將在南京開平價美食廣場 有望再開10家│鉅亨網',
                url: 'http://money.msn.com.tw/newsdetail.aspx?a=hote&b=20130107173502980476211'
            },
            {
                date: '2013-01-07',
                text: '【網路新聞】瓦城攜手金鷹商貿集團 進軍陸百貨美食街 將創新平價品牌│鉅亨網',
                url: 'https://news.cnyes.com/news/id/1412169'
            },
        ]

        return { Lists }
    }
}
</script>