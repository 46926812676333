<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2016-12-31',
                text: '105年第三季合併財報',
                url: '../upfile/ctxt_463.pdf'
            },
            {
                date: '2016-12-31',
                text: '105年第二季合併財報',
                url: '../upfile/ctxt_462.pdf'
            },
            {
                date: '2016-12-31',
                text: '105年第一季合併財報',
                url: '../upfile/ctxt_461.pdf'
            },
            {
                date: '2016-12-09',
                text: '營業收入公告 ( 105年11月份 )',
                url: '../upfile/ctxt_376.pdf'
            },
            {
                date: '2016-11-07',
                text: '營業收入公告 ( 105年10月份 )',
                url: '../upfile/ctxt_362.pdf'
            },
            {
                date: '2016-10-11',
                text: '營業收入公告 ( 105年09月份 )',
                url: '../upfile/ctxt_361.pdf'
            },
            {
                date: '2016-09-07',
                text: '營業收入公告 ( 105年08月份 )',
                url: '../upfile/ctxt_360.pdf'
            },
            {
                date: '2016-08-08',
                text: '營業收入公告 ( 105年07月份 )',
                url: '../upfile/ctxt_358.pdf'
            },
            {
                date: '2016-07-06',
                text: '營業收入公告 ( 105年06月份 )',
                url: '../upfile/ctxt_357.pdf'
            },
            {
                date: '2016-06-07',
                text: '營業收入公告 ( 105年05月份 )',
                url: '../upfile/ctxt_356.pdf'
            },
            {
                date: '2016-05-10',
                text: '營業收入公告 ( 105年04月份 )',
                url: '../upfile/ctxt_355.pdf'
            },
            {
                date: '2016-04-11',
                text: '營業收入公告 ( 105年03月份 )',
                url: '../upfile/ctxt_354.pdf'
            },
            {
                date: '2016-03-08',
                text: '營業收入公告 ( 105年02月份 )',
                url: '../upfile/ctxt_353.pdf'
            },
            {
                date: '2016-02-15',
                text: '營業收入公告 ( 105年01月份 )',
                url: '../upfile/ctxt_352.pdf'
            },
            {
                date: '2016-01-07',
                text: '營業收入公告 ( 104年12月份 )',
                url: '../upfile/ctxt_351.pdf'
            },
        ]

        return { Lists }
    }
}
</script>