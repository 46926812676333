<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <tr v-for="(List, index) in Lists" :key="index" v-once class="h-8 even:bg-bgtable">
            <td class="text-center">{{ List.date }}</td>
            <td class="pl-12">{{ List.text }}</td>
            <td class="text-center">
                <a :href="List.url" target="_blink">
                    <DownloadIcon class="h-5 text-red-700 mx-auto cursor-pointer transition duration-300 ease-in-out transform hover:translate-y-1" />
                </a>
            </td>
        </tr>
    </tbody>
</table>
</template>

<script>
import { DownloadIcon } from '@heroicons/vue/outline'
export default {
    components: { DownloadIcon },
    setup() {
        const Lists = [
            {
                date: '2017-12-29',
                text: '106年股東會議事錄',
                url: '../upfile/ctxt_418.pdf'
            },
            {
                date: '2017-06-13',
                text: '105年年報',
                url: '../upfile/ctxt_408.pdf'
            },
            {
                date: '2017-06-07',
                text: '106年股東會開會通知',
                url: '../upfile/ctxt_407.pdf'
            },
            {
                date: '2017-06-07',
                text: '	106年股東會議事手冊',
                url: '../upfile/ctxt_405.pdf'
            },
        ]

        return { Lists }
    }
}
</script>