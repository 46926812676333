<template>
<div class="w-900 mx-auto mb-10">
    <img src="@/assets/about_t4.jpg">
    <div class="flex">
        <div class="mr-3 w-full">
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">1990年</p>
                <p>
                    開設第一家【瓦城泰國料理】掀起泰菜餐飲風潮<br>
                    年營收新台幣$30,000,000元
                </p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">1992年</p>
                <p>導入廚房工作流程管理系統</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">1993年</p>
                <p>導入分店POS系統<br>成立原物料採購品保團隊</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">1995年</p>
                <p>開設第一家【非常泰概念餐坊】成為時尚餐廳的先驅與指標</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">1996年</p>
                <p>徐承義董事長成立廚藝研究發展中心，進行研發、技術創新與品質標準化</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">1997年</p>
                <p>建立廚務人才培育暨發展制度，因應展店計劃的職缺/職能需求</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">1998年</p>
                <p>建立服務人才培育暨發展制度，落實服務流程標準化</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2000年</p>
                <p>集團總店數達10家<br>成立資源運籌中心，提升食材物料品質及供應服務，以因應集團展店規模</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2003年</p>
                <p>規劃暨落實「神秘訪客」檢視制度，穩定顧客滿意品質</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2004年</p>
                <p>總公司遷移及擴建，鄰近資源運籌中心，強化集團後勤支援之功能效率<br>年營收達新台幣$380,000,000元</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2005年</p>
                <p>首創業界內部廚師培育機構，成立「廚藝管理學院」並正式啟用</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2006年</p>
                <p>瓦城首度進駐大台中地區，引領泰菜風潮<br>開設第一家【1010湘】湖南料理深受明星名人喜愛<br>年營收突破新台幣$500,000,000元</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2008年</p>
                <p>集團總店數突破20家<br>年營收突破新台幣$750,000,000元</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2009年</p>
                <p>服務來客數超過年度200萬人次<br>瓦城榮獲《遠見雜誌》第七屆傑出服務評鑑全國連鎖餐飲第一名</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2010年</p>
                <p>開設瓦城高雄三越左營店，完成南進高雄地區佈局<br>全年營收突破新台幣$1,100,000,000元<br>瓦城蟬聯《遠見雜誌》第八屆傑出服務評鑑全國連鎖餐飲第一名</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2011年</p>
                <p>進駐台南府城，全集團達成全台六都市場佈局<br>集團總店數達30家店之里程碑<br>服務來客數突破年度250萬人次<br>全年營收突破新台幣$1,500,000,000元<br>集團登錄興櫃</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2012年</p>
                <p>通過櫃買中心審核，9月17日正式掛牌上櫃<br>非常泰首度進駐台南府城、集團總店數達40家店之里程碑</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2013年</p>
                <p>瓦城與金鷹商貿集團合資成立子公司，進軍大陸市場<br>集團第60家分店-瓦城上海芮歐店，進駐上海靜安寺芮歐百貨<br>徐承義董事長榮獲『安永企業家獎』、『創業楷模獎』、『金炬獎』獎項<br>全年營收突破新台幣$2,300,000,000元</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2014年</p>
                <p>開設第一家【大心 新泰式麵食】引爆泰麵美食的新風潮<br>集團第五品牌【十食湘BISTRO】進駐上海靜安寺芮歐百貨</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2016年</p>
                <p>啟動「全球研發中心計畫」</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2017年</p>
                <p>開設集團第6個自主研發全新品牌【時時香SHANN RICE BAR】</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2019年</p>
                <p>開設集團第7個自主研發品牌【YABI KITCHEN】<br>開設集團第8個自主研發品牌【月月 泰BBQ】<br>集團來客數達8,600,000人次</p>
            </div>
            <div class="flex">
                <p class="mr-10 font-extrabold text-title1">2021年</p>
                <p>「2021年 發展全台最大連鎖雲端廚房」</p>
            </div>
        </div>
        <div class="flex-none">
            <img src="@/assets/about_t4a.jpg">
        </div>
    </div>
</div>
</template>

<script>
export default {}
</script>