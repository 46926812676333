<template>
<div class="w-900 mx-auto mb-10">
    <img src="@/assets/join_t1.jpg">
    <div class="w-full">
        <h1 class="font-bold">
            我們相信，有快樂進取的團隊，才能提供顧客最滿意的菜色與服務，<br>
            歡迎充滿熱誠與活力的你/妳，加入瓦城泰統集團這個大家庭，讓您擁有全方位發展前景！
        </h1>
        <h2 class="font-bold text-sm mt-6">培育廚藝兼具專業管理能力的廚師</h2>
        <p>
            ◎系統化的訓練考核制度 – 11級職階臂章<br>
            &emsp;透過產品製作訓練、烹調技能精進、廚房營運管理、團隊領導、目標管理…等專業養成，培訓全方位的廚務管理人才。<br>
            ◎成立廚藝管理學院，創造公平的學習環境與晉升機會。<br>
            ◎烹調流程標準化暨分工，建立最佳效率又互助合作的廚房團隊。
        </p>
        <h2 class="font-bold text-sm mt-4">讓服務人才擁有多元化的職涯發展</h2>
        <p>
            ◎系統化的訓練考核制度 – 11級職階徽章<br>
            &emsp;完整的職前訓練、服務技能、訓練員研習、餐廳營運管理、開店籌劃、團隊領導、績效管理與達成…等訓練，不斷拓展專業能力。<br>
            ◎專案培育主管人才，包含單店到多店管理、區域管理、開店專案、服務設計、人員訓練規劃，提供多元化職涯成長。<br>
            ◎集團朝向多品牌、跨區域、國際化的擴展計劃，讓熱愛餐飲服務的您，有一展長才、實現自我的舞台。
        </p>
        <p class="my-4">
            您可以前往<a class="underline" href="https://www.104.com.tw/company/wjm40k8?jobsource=n104bank1" target="_blank">104人力銀行</a>，查詢現有職缺。<br>
            也可將履歷自傳 Email 至 <a class="underline" href="mailto:recruit_team@ttfb.com" target="_blank">recruit_team@ttfb.com</a> 或來電至 02-8227-1280 分機138、156<br>
            我們設有「人才庫」，即使現在沒有適合您的職缺，我們仍然歡迎您的自我推薦。
        </p>
        <p class="my-4">
            溫馨叮嚀所有求職者提高警覺，慎防詐騙事件。<br>
            本公司凡通知求職者面談，必安排於辦公據點或營業店點，並詳細向求職者說明職缺名稱、工作內容，<br>
            與留下公司聯絡人姓名、單位及電話，以供求職者查詢。<br>
            職缺相關資訊，歡迎洽詢 <a class="underline" href="mailto:recruit_team@ttfb.com" target="_blank">recruit_team@ttfb.com</a> 或來電至 02-8227-1280 分機138、156，確認相關細節後再參與面試。
        </p>
        <p class="my-4">
            依據性別工作平等法，為防治工作場所性騷擾行為，維護性別工作平等及人格尊嚴，凡有受性騷擾情事者，皆可使用下列內外部管道提出申訴：
            <ul class="list-decimal list-inside">
                <li>政府保護專線：113</li>
                <li>公司申訴專線：(02)8227-2636 / 電子信箱：<a class="underline" href="mailto:hrservice@ttfb.com" target="_blank">hrservice@ttfb.com</a></li>
            </ul>
        </p>
        <h1>人才招募</h1>
        <a href="https://www.104.com.tw/company/wjm40k8?jobsource=n104bank1" target="_blank"><img src="@/assets/join_t2.jpg"></a>
    </div>
</div>
</template>

<script>
export default {}
</script>