<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2015-06-09',
                text: '【新聞報導】瓦城+21% 本季業績飄香│經濟日報',
                url: '../upfile/ctxt_332.pdf'
            },
            {
                date: '2015-05-15',
                text: '【新聞報導】瓦城躍Q1餐飲股獲利王 每股賺3.36元 連假加持本季業績可期│蘋果日報',
                url: '../upfile/ctxt_330.pdf'
            },
            {
                date: '2015-05-11',
                text: '【新聞報導】瓦城上月營收年增3成笑傲餐飲業│工商時報',
                url: '../upfile/ctxt_329.pdf'
            },
            {
                date: '2015-04-09',
                text: '【網路新聞】瓦城布局兩岸 年底展店95家│工商時報',
                url: 'https://www.chinatimes.com/newspapers/20150409000239-260206?chdtv'
            },
            {
                date: '2015-04-09',
                text: '【網路新聞】瓦城 王品搶推中餐品牌│蘋果日報',
                url: 'https://tw.appledaily.com/finance/20150409/UHUGHUAZ4OPEDGP4HLQAMNBMXM/'
            },
            {
                date: '2015-04-09',
                text: '【網路新聞】瓦城將發10元現金股利　下半年開新品牌│壹週刊',
                url: 'https://tw.nextmgz.com/realtimenews/news/17712923'
            },
            {
                date: '2015-04-08',
                text: '【網路新聞】實現「1年1品牌」策略 瓦城下半年於台北再開中餐新品牌│鉅亨網',
                url: 'https://news.cnyes.com/20150408/%E5%AF%A6%E7%8F%BE1%E5%B9%B41%E5%93%81%E7%89%8C%E7%AD%96%E7%95%A5-%E7%93%A6%E5%9F%8E%E4%B8%8B%E5%8D%8A%E5%B9%B4%E6%96%BC%E5%8F%B0%E5%8C%97%E5%86%8D%E9%96%8B%E4%B8%AD%E9%A4%90%E6%96%B0%E5%93%81%E7%89%8C-171528675939310.shtml?c=special'
            },
            {
                date: '2015-04-08',
                text: '【網路新聞】瓦城拓點邁大步 明年目標百店│中央通訊社',
                url: 'https://www.cnabc.com/news/aall/201504080510.aspx'
            },
            {
                date: '2015-03-31',
                text: '【新聞報導】瓦城去年EPS10.89元 配息10元│工商時報',
                url: '../upfile/ctxt_326.pdf'
            },
            {
                date: '2015-03-31',
                text: '	【新聞報導】瓦城發股息10元 新高│經濟日報',
                url: '../upfile/ctxt_325.pdf'
            },
            {
                date: '2015-03-30',
                text: '【網路新聞】瓦城去年每股賺10.89元 創掛牌以來新高│自由時報',
                url: 'https://ec.ltn.com.tw/article/breakingnews/1272429'
            },
            {
                date: '2015-03-30',
                text: '【網路新聞】瓦城獲利再創高！連2年賺逾1股本 每股將配息10元│鉅亨網',
                url: 'https://news.cnyes.com/news/id/444558'
            },
            {
                date: '2015-03-10',
                text: '【新聞報導】瓦城上月營收再創歷史新高│經濟日報',
                url: '../upfile/ctxt_317.pdf'
            },
            {
                date: '2015-02-10',
                text: '【新聞報導】瓦城迎春節旺季 後市俏 │經濟日報',
                url: '../upfile/ctxt_315.pdf'
            },
            {
                date: '2015-02-05',
                text: '	【新聞報導】瓦城發股息10元 新高│經濟日報',
                url: '../upfile/ctxt_325.pdf'
            },
            {
                date: '2015-03-31',
                text: '【網路新聞】瓦城「大心」加速展店！農曆年前一口氣開3店 首次進軍南台灣│鉅亨網',
                url: 'https://news.cnyes.com/Content/20150205/KKH49MUZHI98J.shtml'
            },
            {
                date: '2015-01-28',
                text: '【網路新聞】瓦城搶灘上海　再下一城│壹週刊',
                url: 'https://tw.nextmgz.com/realtimenews/news/13936825'
            },
            {
                date: '2015-01-28',
                text: '【新聞報導】瓦城大陸第4家分店開幕，今年續鎖定上海拓新據點 │MoneyDJ',
                url: 'https://www.moneydj.com/KMDJ/News/NewsViewer.aspx?a=c3245cec-4f0d-41d1-8349-acfa86c11fc9'
            },
            {
                date: '2015-01-09',
                text: '【新聞報導】瓦城+25% 加速兩岸拓點│經濟日報',
                url: '../upfile/ctxt_310.pdf'
            },
            {
                date: '2015-01-08',
                text: '【網路新聞】瓦城去年營收突破29億元 年增逾25% 再創新猷！│鉅亨網',
                url: 'https://news.cnyes.com/20150108/%E7%93%A6%E5%9F%8E%E5%8E%BB%E5%B9%B4%E7%87%9F%E6%94%B6%E7%AA%81%E7%A0%B429%E5%84%84%E5%85%83-%E5%B9%B4%E5%A2%9E%E9%80%BE25-%E5%86%8D%E5%89%B5%E6%96%B0%E7%8C%B7-163028694768810.shtml?c=headline_sitehead'
            },
        ]

        return { Lists }
    }
}
</script>