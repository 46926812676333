<template>
<table class="w-900 mt-4 mb-9 text-xs table-fixed">
    <thead class="bg-gray-500 text-white font-bold">
        <tr class="h-8">
            <th class="w-2/12">日 期</th>
            <th class="w-8/12">標 題</th>
            <th class="w-2/12">附 檔</th>
        </tr>
    </thead>
    <tbody>
        <table-loop v-for="(list, index) in Lists" :key="index" :date="list.date" :text="list.text" :url="list.url" />
    </tbody>
</table>
</template>

<script>
import TableLoop from '../../../components/TableLoop.vue'
export default {
    components: { TableLoop },
    setup() {
        const Lists = [
            {
                date: '2019-12-31',
                text: '107 年度財務報告書 -個體',
                url: '../upfile/ctxt_513.pdf'
            },
            {
                date: '2019-12-31',
                text: '107 年度財務報告書 -合併',
                url: '../upfile/ctxt_512.pdf'
            },
            {
                date: '2019-12-31',
                text: '108 年 第三季 財務報告書',
                url: '../upfile/ctxt_511.pdf'
            },
            {
                date: '2019-12-31',
                text: '108 年 第二季 財務報告書',
                url: '../upfile/ctxt_510.pdf'
            },
            {
                date: '2019-12-31',
                text: '108 年 第一季 財務報告書',
                url: '../upfile/ctxt_509.pdf'
            },
            {
                date: '2019-12-10',
                text: '營業收入公告 ( 108年11月份 )',
                url: '../upfile/ctxt_494.pdf'
            },
            {
                date: '2019-11-06',
                text: '營業收入公告 ( 108年10月份 )',
                url: '../upfile/ctxt_493.pdf'
            },
            {
                date: '2019-10-14',
                text: '營業收入公告 ( 108年09月份 )',
                url: '../upfile/ctxt_492.pdf'
            },
            {
                date: '2019-09-19',
                text: '本公司受邀參加永豐金證券第三季論壇',
                url: '../upfile/ctxt_490.pdf'
            },
            {
                date: '2019-09-10',
                text: '營業收入公告 ( 108年08月份 )',
                url: '../upfile/ctxt_489.pdf'
            },
            {
                date: '2019-08-19',
                text: '本公司受邀參加富邦證券舉辦之『法人座談會』',
                url: '../upfile/ctxt_488.pdf'
            },
            {
                date: '2019-08-07',
                text: '營業收入公告 ( 108年07月份 )',
                url: '../upfile/ctxt_487.pdf'
            },
            {
                date: '2019-07-09',
                text: '營業收入公告 ( 108年06月份 )',
                url: '../upfile/ctxt_486.pdf'
            },
            {
                date: '2019-06-10',
                text: '營業收入公告 ( 108年05月份 )',
                url: '../upfile/ctxt_485.pdf'
            },
            {
                date: '2019-05-10',
                text: '營業收入公告 ( 108年04月份 )',
                url: '../upfile/ctxt_484.pdf'
            },
            {
                date: '2019-04-10',
                text: '營業收入公告 ( 108年03月份 )',
                url: '../upfile/ctxt_483.pdf'
            },
            {
                date: '2019-04-03',
                text: '本公司受邀參加108年4月8日由臺灣證券交易所及寬量國際共同於香港主辦「2019 Taiwan CEO Week」，向投資人說明本公司之營運概況、財務及業務相關資訊。',
                url: '../upfile/ctxt_482.pdf'
            },
            {
                date: '2019-03-22',
                text: '本公司受邀參加美林證券舉辦之「Asia Pacific Telecom, Media & Technology Conference 2019」-中文',
                url: '../upfile/ctxt_480.pdf'
            },
            {
                date: '2019-03-22',
                text: '本公司受邀參加美林證券舉辦之「Asia Pacific Telecom, Media & Technology Conference 2019」-英文',
                url: '../upfile/ctxt_481.pdf'
            },
            {
                date: '2019-03-21',
                text: '本公司受邀參加凱基證券舉辦之『法人座談會』-中文',
                url: '../upfile/ctxt_479.pdf'
            },
            {
                date: '2019-03-21',
                text: '本公司受邀參加凱基證券舉辦之『法人座談會』-英文',
                url: '../upfile/ctxt_478.pdf'
            },
            {
                date: '2019-03-11',
                text: '營業收入公告 ( 108年02月份 )',
                url: '../upfile/ctxt_476.pdf'
            },
            {
                date: '2019-02-15',
                text: '營業收入公告 ( 108年01月份 )',
                url: '../upfile/ctxt_475.pdf'
            },
            {
                date: '2019-01-09',
                text: '營業收入公告 ( 107年12月份 )',
                url: '../upfile/ctxt_450.pdf'
            },
        ]

        return { Lists }
    }
}
</script>