<template>
<div class="relative w-900 mx-auto mb-10 z-10">
    <img src="@/assets/news_t.jpg">
    <div class="absolute right-0 flex items-center">
        <p class="font-semibold text-sm">新聞年度資料： &nbsp; </p>
        <Menu as="div" class="relative inline-block text-left">
            <div>
                <MenuButton class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                    {{ currentYear }}
                    <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </MenuButton>
            </div>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div class="py-1">
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'N2021'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2021</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'N2020'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2020</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'N2016'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2016</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'N2015'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2015</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'N2014'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2014</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'N2013'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2013</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'N2012'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2012</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'N2011'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2011</p>
                        </MenuItem>
                        <MenuItem v-slot="{ active }">
                            <p @click="currentTab = 'N2010'" class="cursor-pointer"  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">2010</p>
                        </MenuItem>
                    </div>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</div>
<div class="w-900 mx-auto mb-10">
    <component :is="currentTabComponent" />
</div>
<div class="w-900 mx-auto">
    <div class="flex justify-center mb-12">
        <img src="@/assets/covi19.jpg">
    </div>
</div>

</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import N2010 from '@/views/news/N2010.vue'
import N2011 from '@/views/news/N2011.vue'
import N2012 from '@/views/news/N2012.vue'
import N2013 from '@/views/news/N2013.vue'
import N2014 from '@/views/news/N2014.vue'
import N2015 from '@/views/news/N2015.vue'
import N2016 from '@/views/news/N2016.vue'
import N2020 from '@/views/news/N2020.vue'
import N2021 from '@/views/news/N2021.vue'
export default {
    components: {
        N2010, N2011, N2012, N2013, N2014, N2015, N2016, N2020, N2021,
        // UI
        Menu, MenuButton, MenuItem, MenuItems, ChevronDownIcon,
    },

    data() {
        return {
            currentTab: 'N2021',
        }
    },
    computed: {
        currentTabComponent() {
        return `${ this.currentTab.toLowerCase() }`;
        },
        currentYear() {
            return `${ this.currentTab.substr(1) }`;
        }
    }
}
</script>